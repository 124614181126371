#wpadminbar {
    position: fixed;
    background: none;
    height: 60px;

    .quicklinks,
    .ab-top-menu {
        height: 32px;
    }

    .quicklinks {
        display: flex;
        justify-content: space-between;
    }

    #wp-admin-bar-root-default {
        max-width: 38px;
        overflow: hidden;
        background: #333;
    }

    #wp-admin-bar-top-secondary {
        display: none;
    }

    &:hover {
        .quicklinks {
            background: #333;
        }

        #wp-admin-bar-root-default {
            max-width: none;
            overflow: visible;
        }

        #wp-admin-bar-top-secondary {
            display: block;
        }
    }
}

.wp-block-image {
    .aligncenter {
        text-align: center;
    }

    .alignleft {
        text-align: left;
    }

    .alignright {
        text-align: right;
    }

    img {
        max-width: 100%;
        vertical-align: middle;

        @media (max-width: 767px) {
            width: 100%;
            height: auto;
        }
    }
}