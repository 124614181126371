.modal {
    width: 100%;
    max-width: 90vw;
    position: relative;
    z-index: 1;
    transform: scale(0.8);
    transition: transform 0.6s;
    padding: 4rem;
    height: 90vh;

    body.is-loaded & {
    display: block;
    }

    @media (max-width: 767px) {
        width: 96%;
        padding: 40px;
    }

    .is-open & {
        transform: scale(1);
    }

    &-container {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s, visibility 0s linear 0.4s;

        &.is-open {
            transition-delay: 0s;
            opacity: 1;
            visibility: visible;
        }
    }

    &-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        background: rgba(0,0,0, 0.8);
    }

    &-close {
        margin: 0;
        padding: 0;
        display: block;
        border: none;
        position: absolute;
        top: 12px;
        right: 12px;
        background: none;
        padding: 10px;
        font-size: rem(30);
        line-height: 1;
        color: white;
        cursor: pointer;

        & > * {
            pointer-events: none;
        }

        @media (max-width: 767px) {
            font-size: rem(32);
            right: 0;
            top: 0;
            padding: 12px;
        }
    }

    .email-form {
        .button-form {
            max-width: 100%;
        }
    }
}

.modal-img{
    cursor: pointer;
}


element.style {
}
.modal-content {
    background-color: white;
    padding: 2rem;
    max-width: 30rem;
    width: 100%;
    margin: 0 auto;
    overflow: scroll;
    height: 100%;
    min-height: 35rem;
    max-height: 35rem;
    p{
        margin-bottom: 1rem;
    }
}
.modal.content-modal {
    max-width: 40rem;
}