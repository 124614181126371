.countyApplicationTabs {
    padding-bottom: 0;
    margin-bottom: 0;
}

.step-map {
	font-size: 0;
	margin: 0 2px 12px 2px;
	
	a {
		font-size: 16px;
		font-weight: 600;
		display: inline-block;
		padding: 4px 14px;
		background: #ececec;
		color: #6b6b6b;
		position: relative;
		z-index: 5;
		
		&:first-child {
			padding-left: 10px;
			z-index: 10;
			
			@include breakpoint(lt-tablet) {
				padding-left: 9px;
			}
		}
		
		&:last-child {
			z-index: 1;
		}
		
		&.complete {
			background: #005274;
			color: #e3e3e3;
		}
		
		&.current {
			color: #fff;
			//padding-left: 24px;
			
			//	&:after {
			//		content: '';
			//		width: 0;
			//		height: 0;
			//		display: inline-block;
			//		position: absolute;
			//		left: 0;
			//		top: 0;
			//		border-left: 18px solid $blueLight;
			//		border-top: 16px solid transparent;
			//		border-bottom: 16px solid transparent;
			//		z-index: 5px;
			//	}
				
		}
		
		@include breakpoint(lt-tablet) {
			padding: 4px 9px;
			font-size: 12px;
		}
	}
}

div.countyApplicationTabs div.countyApplicationTab {
    display: inline;
    margin-right: 1rem;
    
}

.countyApplicationTabs {
	padding-bottom: 0px;
	margin-bottom: 0px;
}

div.countyApplicationTabs div.countyApplicationTab {
	display: inline;
}

.box {
    padding: 24px;
    margin-bottom: 24px;
    background: #f2f4f7;
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
    border: none;
    display: block;
}

.box.trans {
    background-color: transparent;
    border-top: none;
}

.box p {
    font-size: 14px;
}

.clear {
    clear: both;
    padding: 20px 0;
}

.expand-transition {
    transition: all .3s ease;
    overflow: hidden;
}

.link-icon.close {
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
}
.close {
    color: #45678d;
    float: right;
    font-size: 28px;
    font-weight: 500;
}
.link-icon {
    font-size: 32px;
    -ms-transform: translateY(3px);
    transform: translateY(3px);
    display: inline-block;
    line-height: 1;
}

.erf-container {
    width: 100%;
    max-width: 1028px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
  }
  
  .erf {
    padding: 30px;
    font-size: 16px;
    
    .text-center {
      text-align: center;
    }
    
    h1, h2 {
      font-weight: 700;
      line-height: 1.2;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    h1 {
      margin: 0 0 30px;
      font-size: 40px;
    }
    
    h2 {
      margin: 0 0 20px;
      font-size: 28px;
    }
    
    p {
      margin: 20px 0;
      
      &.has-extra-space {
        margin: 40px 0;
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    a {
      color: #07a;
      text-decoration: underline;
      
      &:hover {
        color: #016089;
        text-decoration: none;
      }
    }
    
    strong {
      font-weight: 700;
    }
    
    .btn {
      min-width: 130px;
      padding: 15px 25px;
      border: none;
      background: #042E60;
      background-repeat: no-repeat;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      appearance: none;
      cursor: pointer;
      
      &[disabled] {
        visibility: hidden;
      }
      
      &:hover {
        background-color: #A46DE3;
      }
      
      &-basic {
        background-color: transparent;
        color: #042E60;
        
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
      }
      
      &.has-arrow-right {
        padding-right: 48px;
        background-position: calc(100% - 20px) center;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi45NjMiIGhlaWdodD0iMTUuNzM3IiB2aWV3Qm94PSIwIDAgMTYuOTYzIDE1LjczNyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzODMuNzMyIC05ODQuNzgpIj48cGF0aCBkPSJNMzU2NSw5OTIuNWgxMy43NzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMTgwLjI2OCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJNMzU4Ni4yMzgsOTg2LjE4NGw1LjE3Niw2LjYzNS01LjE3Niw2LjI5MSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxOTIpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9nPjwvc3ZnPg==");
      }
      
      &.has-arrow-left {
        padding-left: 48px;
        background-position: 20px center;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi45NjMiIGhlaWdodD0iMTUuNzM3IiB2aWV3Qm94PSIwIDAgMTYuOTYzIDE1LjczNyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQwMC42OTUgMTAwMC41MTcpIHJvdGF0ZSgxODApIj48cGF0aCBkPSJNMzU2NSw5OTIuNWgxMy43NzMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMTgwLjI2OCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzA0MmU2MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiLz48cGF0aCBkPSJNMzU4Ni4yMzgsOTg2LjE4NGw1LjE3Niw2LjYzNS01LjE3Niw2LjI5MSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxOTIpIiBmaWxsPSJub25lIiBzdHJva2U9IiMwNDJlNjAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9nPjwvc3ZnPg==");
      }
    }
  }
  
  .erf-intro {
  }
  
  .erf-form {
    background: #f2f4f7;
    
    &-group {
      margin: 0 0 20px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    &-label {
      margin-bottom: 5px;
      display: block;
      
      .has-error & {
        color: red;
      }
    }
    
    &-input {
      width: 100%;
      margin: 0 !important;
      padding: 10px !important;
      border: solid 1px #707070;
      background: #fff;
      font: inherit !important;
      appearance: none;
      
      .has-error & {
        border-color: red;
      }
      
      &-error {
        margin-top: 5px;
        font-size: 12px;
        color: red;
      }
    }
    
    &-select {
      padding-right: 40px;
      background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS42MjEiIGhlaWdodD0iOS45MzIiIHZpZXdCb3g9IjAgMCAxNS42MjEgOS45MzIiPjxwYXRoIGQ9Ik02OTEuNjc3LDExNjkuNTE4bDYuNzUsNi43NSw2Ljc1LTYuNzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02OTAuNjE2IC0xMTY4LjQ1OCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzcwNzA3MCIgc3Ryb2tlLXdpZHRoPSIzIi8+PC9zdmc+") no-repeat calc(100% - 15px) center;
    }
    
    &-radio,
    &-checkbox {
      margin: 5px 0 0;
      display: inline-block;
      cursor: pointer;
      
      .has-error & {
        color: red;
      }
      
      & + & {
        margin-left: 5px;
      }
      
      &.is-block {
        margin-left: 0;
        display: block;
      }
      
      input {
        //display: none;
        display: visible;
        opacity: 0;
        
        &:checked ~ span:before {
          background: #A46DE3;
        }
      }
      
      &-text {
        padding-left: 23px;
        position: relative;
        display: block;
        
        &:before {
          content: "";
          width: 17px;
          height: 17px;
          position: absolute;
          top: 3px;
          left: 0;
          display: block;
          border-radius: 50%;
          border: solid 1px #707070;
          vertical-align: middle;
          background: #fff;
          box-shadow: inset 0 0 0 2px #fff;
          
          .has-error & {
            border-color: red;
          }
        }
      }
    }
    
    &-checkbox {
      &-text {
        &:before {
          border-radius: 3px;
        }
      }
    }
    
    &-required,
    &-note {
      font-style: italic;
      color: #879BB2;
    }
    
    &-note {
      margin-top: 10px;
      font-size: 14px;
    }
    
    &-step-counter {
      margin: 0 auto;
      display: flex;
      position: relative;
      
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      
      &-container {
        display: inline-block;
      }
      
      &:before {
        content: "";
        height: 5px;
        margin: auto;
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 16px;
        right: 16px;
        background: #757575;
      }
      
      &-number {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        background: #757575;
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        line-height: 32px;
        cursor: default;
        
        &.active {
          background: #042E60;
        }
        
        & + & {
          margin-left: 80px;
        }
      }
    }
  }
  
  .erf-flex {
    margin: -10px;
    display: flex;
    flex-flow: row wrap;
    
    & + & {
      margin-top: 10px;
    }
    
    &-margin-top {
      margin-top: 30px !important;
    }
    
    &-no-gutters {
      margin: 0;
    }
    
    &-align-center {
      align-items: center;
    }
    
    &-align-end {
      align-items: flex-end;
    }
    
    &-space-between {
      justify-content: space-between;
    }
    
    @media (max-width: 767px) {
      &-justify-center-mobile {
        justify-content: center;
        
        .erf-flex-col {
          flex: 0 0 auto;
        }
      }
    }
    
    &-divider {
      margin-bottom: 20px;
      padding-bottom: 30px;
      position: relative;
      
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
        border-bottom: solid 1px #ccc;
      }
    }
    
    &-col {
      padding: 10px;
      
      .erf-flex-no-gutters & {
        padding: 0;
      }
      
      &-inset {
        margin-left: 10px;
        padding-left: 20px;
        border-left: solid 1px #ccc;
      }
      
      @media (max-width: 767px) {
        flex: 1 1 100%;
        
        &-first-mobile {
          order: -1;
          flex: 1 1 100% !important;
        }
      }
      
      @media (min-width: 768px) {
        @for $i from 1 through 12 {
          &-#{$i} {
            flex: 1 1 (100% / (12 / $i));
            max-width: 100% / (12 / $i);
          }
        }
        
        &-auto {
          flex: 0 0 auto;
        }
      }
    }
  }
  
  .erf-tooltip {
    position: absolute;
    margin: auto;
    left: -90px;
    bottom: 100%;
    display: block;
    font-size: 12px;
    min-width: 300px;
    background: #fff;
    border: solid 1px #707070;
    padding: 15px;
    transform: translateY(0);
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s, transform 0.2s, visibility 0s linear 0.2s;
    
    @media (max-width: 767px) {
      position: absolute;
      z-index: 3;
      width: calc(100vw - 40px);
      left: -50%;
      right: -50%;
      bottom: auto;
      margin: auto;
    }
    
    &:before, &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 87px;
      border-top: solid 10px #fff;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      
      @media (max-width: 767px) {
        display: none;
      }
    }
    
    &:before {
      z-index: 1;
      left: 86px;
      border-top: solid 11px #707070;
      border-left: solid 11px transparent;
      border-right: solid 11px transparent;
    }
    
    &-icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      background: #D0D0D0;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      line-height: 18px;
    }
    
    &-container {
      display: inline-block;
      
      @media (min-width: 768px) {
        position: relative;
      }
      
      &:hover {
        .erf-tooltip {
          transition-delay: 0s;
          visibility: visible;
          opacity: 1;
          transform: translateY(-5px);
          pointer-events: initial;
        }
      }
    }
  }

  .main.add-region label {
    font-size: 12px;
}

.main .add-county {
	margin: 15px 0 0 10px;
    padding: 0.5rem;
}
.main ul.added-counties .exit {
	border: none;
    color: #005274;
    border-radius: 50%;
    margin: 0 5px 0 0;
    width: auto;
    text-align: center;
    padding: 0rem 0.25rem 0.1rem;
    background: white;
	font-weight: 600;
	cursor: pointer;
}
.form-container {
  max-width: 60rem;
  display: block;
  margin: 0 auto;
}