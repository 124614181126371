@mixin icon {
    font-family: map-get($fonts, icons) !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.icon, [class^="icon-"], [class*=" icon-"] {
    @include icon;
}

@each $key, $value in $icons {
    .icon-#{$key}:before {
        content: $value;
    }
}