.progress-container {
  display: grid;
  grid-template-columns: repeat(1, 260px);
  grid-gap: 80px;
  margin: auto 0;
}



.progress-container .pie-box {
  width: 100%;
}


.progress-container .pie-box .pie-chart {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 40px;
  height: 260px;
  color: #fff;
}

.progress-container .pie-box canvas {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  min-width: 260px;
  max-width: 260px;
  height: auto !important;
  width: auto !important;
}
.pie-chart span{
  font-size: 0.9rem;
}
.progress-container .chart-info{
    position: absolute;
    background-color: white;
    color: #8268A3;
    border-radius: 125px;
    width: 230px;
    height: 230px;
    left: 15px;
    bottom: 2px;
    h2{
        margin-top: 4rem;
        font-size: 2.15rem;
    }
    h2,span{
        line-height: 1;
    }
}
span.percent{
  position: absolute;
}
span.attained{
  display: block;
  max-width: 5rem;
  margin: 0 auto;
}

.chart-wrapper .tippy-body{
  p{
    line-height: 20px;
  }
  h4{
    margin-bottom: 0.25rem;
  }
}

.chart-wrapper{
  .tippy-box{
      @media (max-width: 1500px){
        transform: translate(-20%) !important;
    }
      @media (max-width: 768px){
      transform: translate(0%) !important;
      left: 30% !important;
      max-width: 15rem !important;

    }
  }

}