.desktop-nav{
	@media (max-width: 1200px){
		display: none;
	}
}

.nav {
	display: none;
	margin-left: 0rem;
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 0px;
	}
	@media (max-width: 1200px) {
		width: 100%;
		position: fixed;
		top: 69px;
		bottom: 0;
		left: -100%;
		border-right: solid 1px #ccc;
		background: #fff;
		overflow: auto;
		transition: left 0.4s;
		z-index: 5;
		padding: 0;
		display: block;

		.nav-expanded & {
			left: 0;
		}

		.menu {
		}
		
		.first {
			border-top: solid 1px #eee;
		}

		.sub-menu {
			//padding-left: 20px;
			//margin-bottom: 20px;
			
			&.how-to-sign-up {
				display: none;
			}

			a {
				padding: 15px 25px;
			}

			h4 {
				font-size: 14px;
				color: map-get($colors, 'light-blue');
			}

			.nav-cta {
				display: block;
				border: solid 2px map-get($colors, 'light-blue');
				border-radius: 5px;
				transition: all .2s;
				text-align: center;
				font-size: 13px;
				width: 35%;

				&:hover {
					background-color: map-get($colors, 'light-blue');
					border: solid 2px map-get($colors, 'light-blue');
					color: white;
				}
			}
		}

		li {
			position: relative;

			.more {
				position: absolute;
				right: 15px;
				top: 8px;
				display: block;
				height: 45px;
					width: 50px;
				cursor: pointer;
				background: #ccc;
				line-height: 35px;
				text-align: center;
				text-indent: -9999px;
				font-size: 24px;
				transform: rotate(90deg);
				transition: 0.4s;
				background: url(../img/arrow-blue.png) right center no-repeat;
				background-position: 17px 17px;
			}
		}

		a {
			padding: 15px;
			display: block;
			border-bottom: 1px solid #eee;

			&:hover {
			}
		}
	}

	@media all and (min-width: 768px) {
		.menu {
			> li {
				+ li {
				}
			}
		}

		.sub-menu {
		}

		a {
		}
	}
}

%hamburger {
	.hamburger {
		width: 38px;
		height: 32px;
		position: fixed;
		top: 18px;
		right: 18px;
		display: block;
		outline: none;
        z-index: 10;
        padding: 5px;
        background-color: #fff;

		@media (min-width: 1200px) {
			display: none;
		}

		&:hover {
			.line {
				background: lighten(#4e6b8b, 10%);
			}
		}

		.line {
			width: 100%;
			height: 3px;
			display: block;
			border-radius: 2px;
			background: #4e6b8b;
			transition: transform 0.3s, opacity 0.2s, background 0.2s;
			opacity: 1;

			+ .line {
				margin-top: 7px;
			}

			&.one {
				transform-origin: 10% center;
			}

			&.three {
				transform-origin: 10% center;
			}
		}

		.nav-expanded & {
            .line {
                width: 125%;
            }
			.one {
				transform: rotate(45deg);
			}

			.two {
				transform: rotate(90deg);
				opacity: 0;
			}

			.three {
				transform: rotate(-45deg);
			}
		}
	}
}

.more{	
	@media all and (min-width: 768px){
		display: block;
	}
}

.navigation .hamburger {
	width: 38px;
	height: 32px;
	position: fixed;
	top: 18px;
	right: 18px;
	display: block;
	outline: none;
	z-index: 10;
	padding: 5px;
	background-color: #fff;
  }
  @media (min-width: 1200px) {
	.navigation .hamburger {
	  display: none;
	}
  }
  .navigation .hamburger:hover .line {
	background: #6484a8;
  }
  .navigation .hamburger .line {
	width: 100%;
	height: 3px;
	display: block;
	border-radius: 2px;
	background: #4e6b8b;
	transition: transform 0.3s, opacity 0.2s, background 0.2s;
	opacity: 1;
  }
  .navigation .hamburger .line + .line {
	margin-top: 7px;
  }
  .navigation .hamburger .line.one {
	transform-origin: 10% center;
  }
  .navigation .hamburger .line.three {
	transform-origin: 10% center;
  }
  .nav-expanded .navigation .hamburger .line {
	width: 125%;
  }
  .nav-expanded .navigation .hamburger .one {
	transform: rotate(45deg);
  }
  .nav-expanded .navigation .hamburger .two {
	transform: rotate(90deg);
	opacity: 0;
  }
  .nav-expanded .navigation .hamburger .three {
	transform: rotate(-45deg);
  }

  .accordion {
	
	transition: 0.4s;
  }
  .panel {
	transition: max-height 0.2s ease-out;
	overflow: hidden;
	max-height: 0;
  }

  .accordion.open{
	  .more{
		transform: rotate(0deg);
		transition: 0.4s;
	  }
  }