.act-ribbon {
	max-width: 626px;
	height: 154px;
	margin: 0 auto 40px;
	position: relative;
	.st0{fill:none;stroke:none;}
	&:before, &:after {
		content: "";
		width: 0%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}

	&:before {
		z-index: 1;
		background: url('/img/ribbon-back.png') no-repeat right bottom;
		left: auto;
		right: 0;
		width: 100%;

		.page-loaded & {
			animation: slideBack 0.8s ease-in forwards;
		}
	}

	&:after {
		z-index: 1;
		background: url('/img/ribbon-front.png') no-repeat left bottom;
		width: 100%;

		.page-loaded & {
			animation: slideFront 0.8s ease 0.8s forwards;
		}
	}

	&-svg {
		max-width: 626px;
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		transform: scale(0.9);

		.page-loaded & {
			animation: fadeIn 0.8s ease 1.6s forwards;
		}

		tspan {
			font-size: 20px;
			font-weight: 600;
			fill: #00305F;

			@supports (stroke-location: inside) {
				fill: transparent;
				stroke: #00305F;
				stroke-dasharray: 5;
				stroke-location: inside;
			}

			.page-loaded & {
				animation: stroke 2s ease 1.6s forwards;
			}
		}

		&.second-line {
			top: 26px;
			animation-delay: 1.8s;
		}

		&.third-line {
			top: 52px;
			animation-delay: 2s;
		}
	}
}

.county-certificate {
	max-width: 20rem;
	width: 100%;
  }