.workforce-table {
    margin: 0 0 14px;
    font-size: 13px;
    color: #666;
    width: 100%}
@media all and (max-width:767px) {
    .workforce-table {
        margin: 0px auto 14px;
}
}.workforce-table td, .workforce-table th {
    padding: 5px 9px;
    text-align: center;
    border: solid 1px #ccc;
}
@media all and (max-width:767px) {
    .workforce-table td, .workforce-table th {
    padding: auto;
}
}.workforce-table th {
    font-weight: 600;
    vertical-align: bottom;
    background: #042e60;
    color: #fff;
}
.workforce-table tr.grey td {
    background: #f2f4f7;
}
.workforce-table tr:nth-child(even) td {
    background: #f2f4f7;
}
.workforce-table tr.dark td {
    background: #999;
    color: #fff;
}
.workforce-table td {
    font-weight: 600;
    vertical-align: top;
    background: #fff;
}
.workforce-table td:first-child {
    font-weight: 400;
}
.workforce-table.main-stats {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.workforce-table.main-stats td {
    font-weight: 400;
    text-align: center;
    padding: 4px 8px;
    @media all and (max-width:540px) {
        padding: 4px 0px;
        font-size: 0.8rem;
    }
}
.workforce-table.main-stats thead td {
    background-color: #042e60;
    color: #fff;
    text-align: center;
    vertical-align: middle;
}
.workforce-table.main-stats .totals td {
    background-color: #005274;
    color: #fff;
}
.workforce-table.main-stats .total {
    background-color: #e9f7fc;
}
.workforce-table.main-stats .total.total-total {
    background-color: #005274;
}
.workforce-table.blue-table {
    width: 100%;
    margin-bottom: 0;
}
.workforce-table.blue-table thead td {
    font-size: 8px;
    background-color: #07a;
    color: #fff;
    font-weight: 400;
    padding-top: 5px;
}
.workforce-table.blue-table tr:nth-child(even) td {
    background-color: #fff;
}
.workforce-table.blue-table td {
    border: none;
    border-left: 1px solid #002e62;
    border-top: 1px solid #005274;
    padding: 4px;
    background-color: #f2f4f7;
    text-align: center;
}
.workforce-table.blue-table td:first-child {
    font-size: 10px;
    border-left: none;
    text-align: left;
    padding-right: 8px;
}
.workforce-table-note {
    margin: 0 0 20px;
    padding: 0 10px;
    font-size: 11px;
    color: #666;
}
.earned-chart{
    padding-left: 4rem;
    p{
        margin-top: 0.5rem;
    }
}

table.main-stats-desktop{
    @media(max-width: 600px){
        display: none;
    }
}

table.main-stats-mobile{
    display: none;
    @media(max-width: 600px){
        display: block;
    }
        td.total.right {
            display: table-cell;
        }
        td.right {
            display: none;
        }
        td {
            width: 33%;
        }

}


.certified-box{
    h4{
        color: #0077AA !important;
        font-weight: 600 !important;
        font-size: 1rem !important;
    }
    a{
        font-weight: normal;
        font-size: 0.9rem;
    }
}
.county-names{
    a{
        font-weight: normal;
    }
}


ul.split-list{
    column-count: 2;
    @media(max-width: 1024px){
        column-count: 1;
    }
}