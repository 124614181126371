$layout: (
    width: 1360px,
    gutters: 40px,
    columns: 12
);

$colors: (
    'dark-blue': #042E60,
    'dark-blue-trans' : #042e60cc,
    'light-blue' : #0077AA,
    'purple' : #63428C,
    'light-purple' : #8268A3,
    'green' : #005952,
    'dark-blue-bg' : #c6d0e080,
    'pale-blue-bg' : #F2F4F7,
    'white' : #fff, 
    'black' : #000,
    'input-blue' : #99B8DE,
    'border' : #f2f4f740,
    'gray' : #E6E6E6,
    'inactive' : #042e6040,
    'medium-blue' : #005274,
    'link-alternate' : #C6D0E0,
    'footer-black' : #111,
    'text-gray': #999999,
    'gold' : #C4A06F,
    'light-green' : #319B43,
    'point-green' : #297126,
    'orange' : #ffa500
);

$fonts: (
    primary: (Montserrat, sans-serif),
    icons: ('icons')
);

$icons: (
);

%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}