a.region-moreinfo  {
	p {
		display: inline-block;
	}
	 img {
		display: inline-block;
		margin-left: 10px;
	}
}

.region-container {
	width:100%;
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 20px 0;
	@include breakpoint(lt-tablet) {
	    flex-wrap: wrap;
	    justify-content: center;
	}
	img {
		width: 100%;
		height: auto;
		max-width: none;
	}
    .state-map {
    	width:  47%;
    	@include breakpoint(lt-tablet) {
	          width: 90%;
	    }
    }
	.region {
		width: 50%;
		height: 100%;
		background-color: white;
		text-align: center;
		padding: 15px;
		min-width: 330px;
		@include breakpoint(lt-tablet) {
	          width: 90%;
	    }
		img {
			padding: 0 10px;
			margin-top: -5px;
		}
		.counties-container {
			display: flex;
			width: 100%;
			p {
				text-align: center;
			}
			.counties-list {
				width: 20%;
				text-align: left;
				p {
					margin: 0 0 5px 0;
					color: #4E6B8B;
				    }
			    }
    		.region-map {
    			width: 60%;
     			padding: 0;
    		  }
    	  }
    }
}

.side {
	&.add-region {
		h4 {
			font-weight: normal;
		}

	}

	.btn {
		width: auto;
		margin-bottom: 25px;
	}
}

.main {
	&.add-region {
		position: relative;
		
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}
		
		&.report {
			margin-top: 0;
		}
		
		@include breakpoint(lt-tablet) {
			width: 100%;
		}
		
		label {
			font-size: 12px;
		}

		.pad-right {
			padding-right: 25px;
			
			@include breakpoint(lt-tablet) {
				padding-right: 0;
			}
		}
		.region-buttons  {
			display: flex;
			justify-content: space-evenly;
			
			.mobile-full {
				@include breakpoint(lt-tablet) {
					width: 100%;
				}
			}
		}

		.select-state {
			margin: 0 0 15px;
			font-size: 12px;
			text-align: left;
			padding: 8px 15px;
		}

		.loading {
			background: rgba(0,0,0,.5);
			height: 450px;
			width: 100%;
			position: absolute;
			z-index: 10;

			@include breakpoint(lt-tablet) {
				height: 800px;
			}
		}

		.loader{
		  height: 75px;
		  width: 75px;
		  text-align: center;
		  display: inline-block;
		  position: absolute;
		  z-index: 15;
		  top: 40%;
		  left: 45%;
		}

		svg path,
		svg rect{
		  fill: #FFFFFF;
		}

		#generate.btn {
			width: auto;
			cursor: pointer;
		}

		.box {
			&.box-loading {
				height: 450px;

				@include breakpoint(lt-tablet) {
					height: 800px;
				}
			}
		}
		
		.btn {
			@include breakpoint(lt-tablet) {
				width: 78% !important;
			}
		}
	}

	.generated-link {
		border: 1px solid #45678d;
		background: #FFF;
		padding: 10px;
		width: 100%;
		margin-bottom: 10px;
	}

	.custom-region-link {
		padding-bottom: 18px !important;

		.col-6 {
			@include breakpoint(lt-tablet) {
				width: 50%;
				float: left;
			}
		}
	}

	.career-readiness-certificate {
		padding-bottom: 18px !important;

		.ticker {
			margin-bottom: 10px;
		}

		.ticker-text-label {
			background: none;
			border: none;

			@include breakpoint(lt-tablet) {
				text-align: center;
			}
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
			}
		}
	}

	.selected-counties {

		h4.blue {
			color: #45678d;
			margin-bottom: 5px;
		}
	}

	.add-county {
		margin: 23px 0 0 10px;

		@include breakpoint(lt-tablet) {
			margin: 0 0 0 2px !important;
		}
	}
	
	ul.added-counties {
		padding: 0;
		margin: 7px 0 0 0;

		li {
			list-style: none;
			padding: 7px 0;
			margin: 0;
			display: flex;
    align-items: center;
		}

		.exit {
			border: none;
			color: #005274;
			border-radius: 50%;
			//padding: 0px 5px;
			margin: 0 5px 0 0;
			width: 26px;
		}
		
		a {
			color: #FFF;
		}
	}

	.activate {
        background-color: gray;
        color: #fff;
		cursor: pointer;
		&:after {
		    content: '';
		    width: 0;
		    height: 0;
		    display: inline-block;
		    margin: auto auto auto 5px;
		    border-left: 7px solid #BBB;
		    border-top: 5px solid transparent;
		    border-bottom: 5px solid transparent;
		}

		@include breakpoint(lt-desktop) {
			margin-bottom: 25px;
		}
	}
}

.region-note {
	text-align: center;
}

.regions-table {
	.workforce-table.blue-table td:first-child {
		font-size: 12px;
	  }
	  .workforce-table.blue-table thead td {
		font-size: 11px;
	  }
}