/* Ticker */
.odoTicker {
    height:56px;
    overflow:hidden;
    position:relative;
    left: -1px;
    display: flex;
    justify-content: center;

    .odoNumber {
        height:622px;
        width:36px;
        position:relative;
        background-image:url(../img/ticker-numbers.jpg);    
        float:left;
        overflow:hidden;
        margin:0 1px;
        top: 0;
        transition: top 1.5s ease-in-out;

        @media (max-width: 1200px){
            height: 620px;
        }
        @media (max-width: 1023px){
            width: 34px;
            height: 622px;
        }
    }
    
    @media (max-width: 1200px){
        width: 100%;
    }
    @media (min-width: 1024px){
        left: -1px;
    }
    @media (max-width: 1023px){
        width: 216px;
        //left: 13%;
        margin:auto;
    }
}

.ticker {
    margin: 0 0 15px 0;
    text-align: center;
    
    .employers {
        margin-bottom: 15px;
        @media (max-width: 1023px){
            width: 216px;
        }
    }
    @media (max-width: 1200px){
        width: 216px;
    }
    
    @media (max-width: 1023px){
        margin: 10px auto;
    }
}

.ticker-text-label {
    border: solid 2px #dadada;
    border-top-width: 2px;
    border-radius: 0 0 5px 5px;
    padding: 5px;
    color: #851416;
    text-shadow: 0 1px #f2f2f2;
    font-weight: 600;
    border-top-width: 1px;
    margin-right: 2px;
    margin-bottom: 15px;
    background: #f0f0f0;
    font-size: 14px;
}

.recognized-employers.auto-left {
    width: auto;
    margin-right: 15px;
    float: left;
    
    @media (max-width: 1200px) {
        width: 35%;
    }
}

.ticker-text-label {
    padding: 5px;
    color: map-get($colors, 'green');
    font-weight: 600;
    margin-right: 2px;
    margin-bottom: 15px;
    background: #FFF;
    border-top: 1px solid #f2f4f7;
    border-left: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    border-right: 1px solid #FFF;
}

.header-wheel.ticker-heading {
    margin-bottom: 20px;
    padding-top: 4px;
}