.state-hover-container {
    .county-box {
        width: 150px;
        background: map-get($colors, 'white');
        padding: 10px;
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        z-index: 20;
        text-align: center;

        &:after {
            content: '';
            display: block;
            height: 0;
            width: 0;
            border-top: 20px solid map-get($colors, 'white');
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 100%;

        }

        .cta {
            display:block;
        }

        .percent {
            padding: 1px 6px;
            border: 1px solid map-get($colors, 'text-gray');
            font-weight: 600;
            font-size: 15px;
            position: absolute;
            right: 11%;
            top: 33%;
            background: map-get($colors, 'white');
            color: red;
        }
    }
}


.state-map.active .state-hover-container {
.county-box.active-tooltip {
    display: block;
}
}

.county-employer-list.dark-bulleted-list{
    margin-left: 0rem;
    li{
        &:before{
            content: "";
        }
    }
}

.state-employer-list.dark-bulleted-list{
    margin-left: 0rem;
    li{
        &:before{
            content: "";
        }
    }
}
.added-counties{
    margin-left: 0;
    li{
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;a{
            color: white;
        }
    }
}
.exit {
    border-radius: 25px;
    box-shadow: none;
    border: 0;
    padding-bottom: 0.25rem;
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 0.25rem;
    font-weight: 600;
    cursor: pointer;
  }