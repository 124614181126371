.service-delivery-scroll{
    padding: 1rem;
    margin-top: 1rem;
    text-align: left;
    p{
        font-weight: 600;
    }
    li{
        margin-bottom: 1rem;
    }
    &.service-schools{
        li{
            margin-bottom: 0.5rem;
        }
    }
}

.scroll-arrow{
    position: relative;
    &:after{
        content: url("../img/icon-arrow-blue.png");
        position: absolute;
        margin-left: 0.5rem;
        bottom: 5px;
        right: 10px;

    }

}