body {
    font-family: map-get($fonts, primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-weight: 700;
    line-height: 1.2;

    // + h1, + .h1, + h2, + .h2, + h3, + .h3, + h4, + .h4, + h5, + .h5, + h6, + .h6, + .wp-block-image {
    //     margin-top: rem(map-get($layout, gutters));
    // }
}
.p-margin-bottom{
    p{
        margin-bottom: 1rem;
    }
}
h1, .h1 {
    font-size: rem(40);
}
h1.is-dark-blue{
    margin-bottom: 30px;
}

h2, .h2 {
    font-size: rem(28);
}

h3, .h3 {
    font-size: rem(18);
    margin-bottom: 1rem;
}

h4, .h4 {
    font-size: rem(14);
}

h5, .h5 {
    font-size: rem(13);
}

h6, .h6 {
    font-size: rem(12);
}

p{
    font-size: rem(16);
    line-height: 29px;
}

p, ul, ol {
    margin: 0;

    // + p, + ul, + ol {
    //     margin-top: rem((math.div(map-get($layout, gutters), 2)));
    // }

    &.large {
        font-size: rem(24);
        line-height: 1.4;
    }
}

p, ul, ol, .wp-block-image {
    + .wp-block-image {
        margin-top: rem(map-get($layout, gutters));
    }
}

.lead{
    font-size: 1.5rem;
    font-weight: 500;
    span{
        font-weight: 800;
    }
}

.wp-block-image {
    + h1, + .h1, + h2, + .h2, + h3, + .h3, + h4, + .h4, + h5, + .h5, + h6, + .h6, + p, + ul, + ol {
        margin-top: rem(map-get($layout, gutters));
    }
}

ol {
    list-style: decimal;
}

blockquote {
    margin-bottom: 1.25rem;
    position: relative;
    &:before{
        content: url('../img/icon-quote.png');
            left: -1.85rem;
            top: 1.25rem;
            position: absolute;
            z-index: 1;
    }
    .body-quote{
        padding: 1.25rem 0rem;
        z-index: 2;
        position: relative;
    }
}

ul{
    margin-left: 1rem;
    margin-top: 0rem;
}

a {
    text-decoration: none;
    color: map-get($colors, 'light-blue');
    font-weight: 600;
}
a.normal{
    font-weight: normal;
}
.arrow-left{
    position: relative;
    &:before{
        content: url('../img/icon-arrow-blue-right.png');
        position: relative;
        top: 1px;
        margin-right: 0.5rem;
    }
}
.arrow-left-nav{
    position: relative;
    &:before{
        content: url('../img/icon-arrow-dark.png');
        position: relative;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}
.arrow-left-read-more{
    position: relative;
    margin-left: 0.75rem;
    &:before{
        content: url('../img/icon-arrow-blue-right.png');
        position: absolute;
        left: -0.75rem;
        top: -1px;
    }
}
ul.blue-caret{
    position: relative;
    margin-left: 0.5rem;
    li:before{
        content: url('../img/blue-caret.png');
        position: relative;
        left: -0.5rem;
        top: 1px;
    }
    span {
        width: 10rem;
        display: inline-block;
    }
}
.arrow-circle{
    position: relative;
    margin-left: 0.75rem;
    &:after{
        content: url('../img/icon-arrow-circle-small.png');
        position: absolute;
        right: -2.75rem;
        top: -1rem;
    }
}
ul.arrows li{
    font-size: rem(11.2);
    font-weight: 600;
    color: map-get($colors, 'dark-blue');
    text-indent: -9px; 
    margin-left: 9px;
    &:before{
        content: url('../img/icon-arrow.png');
        margin-right: 0.25rem;
    }
}
ul.bulleted-list li{
    position: relative;
    &:before{
        content: url('../img/bulleted-list.png');
        left: -32px;
        top: -7px;
        position: absolute;
    }
}

.button.blue-dropdown {
    margin: 0;
    position: relative;
    padding: 10px 26px 10px 24px;
    background-color: map-get($colors, 'light-blue');
    color: white;
    transition: all 0.3s ease-in-out 0s;
    text-transform: none;
    &:hover{
        ul.dropdown {
            display: block;
            background-color: map-get($colors, 'light-blue');
            transition: all 0.3s;
            a{
                color: white;
                font-weight: normal;
            }
        }
        &::after{
            transform: scaleY(-1);
            transition: all 0.3s ;
        }
    }
}
.for-communities-sidebar{
    ul{
        margin-left: 0;
        li{
            margin-bottom: 0.75rem;
            a{
                color: white;
            }
        }
    }
}
.button.white-dropdown {
    margin: 0;
    position: relative;
    padding: 5px 30px 6px 8px;
    background-color: white;
    color: map-get($colors, 'dark-blue');
    transition: all 0.3s ease-in-out 0s;
    border: 1px solid lightgray;
    text-transform: none;
    font-size: 0.75rem;
    &:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        ul.dropdown {
            display: block;
            background-color: white;
            transition: all 0.3s;
            padding: 0.5rem 0.6rem;
            border-radius: 0px 0px 5px 5px;
            border-top: 0;
            a{
                color: map-get($colors, 'light-blue');
            }
        }
        &::after{
            transform: scaleY(-1);
            transition: all 0.3s ;
        }
    }
    &:after{
        content: url(../img/icon-arrow-blue.png);
        position: absolute;
        right: 0.5rem;
        top: 4px;
        transition: all 0.3s ;
    }
}

ul.dropdown {
    display: none;
    margin: 0;
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    text-align: left;
    padding: 0.5rem 1.6rem;
    width: 100%;
    left: 0;
    border-radius: 5px;
    position: absolute;
    max-height: 10.5rem;
    overflow: auto;
    transition: all 0.3s;
    z-index: 9;
}

ul.dark-bulleted-list li{
    position: relative;
    &:before{
        content: url('../img/dark-bulleted-list.png');
        left: -14px;
        top: -1px;
        position: absolute;
    }
}

ul.dark-blue-bulleted-list li{
    position: relative;
    &:before{
        content: url('../img/dark-blue-circle.png');
        left: -0.85rem;
        top: -2px;
        position: absolute;
    }
}
nav ul li{
    text-indent: 0;
    font-weight: 600;
    color: map-get($colors, 'dark-blue');
    &:before{
        display: none;
    }
}
label{
    color: map-get($colors, 'dark-blue');
}
strong {
    font-weight: 700;
}

i, em {
    font-style: italic;
}

.is-uppercase {
    text-transform: uppercase;
}

.is-lowercase {
    text-transform: lowercase;
}

.unset-case {
    text-transform: none !important;
}

.is-regular {
    font-weight: 400;
}

.is-semibold {
    font-weight: 600;
}

.is-bold {
    font-weight: 700;
}
hr{
    border: 1px solid map-get($colors, 'link-alternate');
    margin: 1rem auto;
}
img.icon-info {
    margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
    .is-regular-mobile {
        font-weight: 400;
    }
    
    .is-semibold-mobile {
        font-weight: 600;
    }
    
    .is-bold-mobile {
        font-weight: 700;
    }
}

.is-italic {
    font-style: italic;
}

.no-wrap {
    white-space: nowrap;
}

.is-primary {
    font-family: map-get($fonts, primary);
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

@media (max-width: 1023px) {
    .text-left-tablet {
        text-align: left;
    }
    
    .text-center-tablet {
        text-align: center;
    }
    
    .text-right-tablet {
        text-align: right;
    }
}

@media (max-width: 767px) {
    .text-left-mobile {
        text-align: left;
    }
    
    .text-center-mobile {
        text-align: center;
    }
    
    .text-right-mobile {
        text-align: right;
    }
}

.font-inherit {
    margin: 0;
    padding: 0;
    display: inline;
    font: inherit;
    color: inherit;
    line-height: inherit;
    text-decoration: inherit;
    letter-spacing: inherit;
}

a.social-link{
    img{
        margin-right: 0.25rem;
    }
}