body{

}
.container {
    width: 100%;
    max-width: map-get($layout, width);
    margin: 0 auto;
    padding: 0 math.div(map-get($layout, gutters), 2);
}

.z-high,
.z-med,
.z-low {
    position: relative;
    z-index: 5;
}

.z-med {
    z-index: 3;
}

.z-low {
    z-index: 1;
}

.flex {
    display: flex;
    margin: -(math.div(map-get($layout, gutters), 2));

    & + & {
        margin-top: math.div(map-get($layout, gutters), 2);
    }

    & + &.has-gutters-top-none {
        margin-top: 0;
    }

    &-no-gutters {
        margin: 0;
    }

    &-double-gutters {
        margin: -(map-get($layout, gutters));

        & + & {
            margin-top: map-get($layout, gutters);
        }
    }

    &-half-gutters {
        margin: -(math.div(map-get($layout, gutters), 4));

        & + & {
            margin-top: math.div(map-get($layout, gutters), 4);
        }
    }

    @media (max-width: 767px) {
        &-no-gutters-mobile {
            margin: 0;
        }

        &-double-gutters-mobile {
            margin: -(map-get($layout, gutters));

            & + & {
                margin-top: map-get($layout, gutters);
            }
        }

        &-half-gutters-mobile {
            margin: -(math.div(map-get($layout, gutters), 4));

            & + & {
                margin-top: math.div(map-get($layout, gutters), 4);
            }
        }
    }

    &-wrap {
        flex-flow: row wrap;
    }

    &-wrap-auto {
        flex-flow: row wrap;
    }

    &-direction-reverse {
        flex-direction: row-reverse;
    }

    &-direction-column {
        flex-direction: column;
    }

    &-direction-column-reverse {
        flex-direction: column-reverse;
    }

    @media (max-width: 767px) {
        &-direction-row-reverse-mobile {
            flex-direction: row-reverse;
        }

        &-direction-column-mobile {
            flex-direction: column;
        }

        &-direction-column-reverse-mobile {
            flex-direction: column-reverse;
        }
    }

    &-space-between {
        justify-content: space-between;
    }

    &-space-around {
        justify-content: space-around;
    }

    &-align-center {
        align-items: center;
    }

    &-align-stretch {
        align-items: stretch;
    }

    &-align-start {
        align-items: flex-start;
    }

    &-align-end {
        align-items: flex-end;
    }

    &-justify-center {
        justify-content: center;
    }

    &-justify-start {
        justify-content: flex-start;
    }

    &-justify-end {
        justify-content: flex-end;
    }

    @media (max-width: 1023px) {
        &-space-between-tablet {
            justify-content: space-between;
        }

        &-space-around-tablet {
            justify-content: space-around;
        }

        &-align-center-tablet {
            align-items: center;
        }

        &-align-stretch-tablet {
            align-items: stretch;
        }

        &-align-start-tablet {
            align-items: flex-start;
        }

        &-align-end-tablet {
            align-items: flex-end;
        }

        &-justify-center-tablet {
            justify-content: center;
        }

        &-justify-start-tablet {
            justify-content: flex-start;
        }

        &-justify-end-tablet {
            justify-content: flex-end;
        }
    }

    @media (max-width: 767px) {
        &-space-between-mobile {
            justify-content: space-between;
        }

        &-space-around-mobile {
            justify-content: space-around;
        }

        &-align-center-mobile {
            align-items: center;
        }

        &-align-stretch-mobile {
            align-items: stretch;
        }

        &-align-start-mobile {
            align-items: flex-start;
        }

        &-align-end-mobile {
            align-items: flex-end;
        }

        &-justify-center-mobile {
            justify-content: center;
        }

        &-justify-start-mobile {
            justify-content: flex-start;
        }

        &-justify-end-mobile {
            justify-content: flex-end;
        }
    }

    &-col {
        flex: 1 1 auto;
        flex-basis: 80px;
        padding: (math.div(map-get($layout, gutters), 2));

        &-no-gutters {
            padding: 0;
        }

        .flex-no-gutters > & {
            padding: 0;
        }

        .flex-double-gutters > & {
            padding: map-get($layout, gutters);
        }

        .flex-half-gutters > & {
            padding: math.div(map-get($layout, gutters), 4);
        }

        @media (max-width: 767px) {
            .flex-no-gutters-mobile > & {
                padding: 0;
            }

            .flex-double-gutters-mobile > & {
                padding: map-get($layout, gutters);
            }

            .flex-half-gutters-mobile > & {
                padding: math.div(map-get($layout, gutters), 4);
            }

            &-mobile-no-gutters {
                padding: 0;
            }
        }

        .flex-wrap > & {
            @media (max-width: 767px) {
                flex: 1 1 100%;
            }
        }

        &-auto {
            flex: 0 0 auto;
        }

        &.flex-align-center {
            align-self: center;
        }

        &.flex-align-stretch {
            align-self: stretch;
        }

        &.flex-align-start {
            align-self: flex-start;
        }

        &.flex-align-end {
            align-self: flex-end;
        }

        @media (max-width: 767px) {
            &.flex-first-mobile {
                order: -1;
            }
        }

        @media (max-width: 767px) {
            @for $i from 1 through map-get($layout, columns) {
                &-mobile-#{$i} {
                    flex: 1 1 math.div(100%, math.div(map-get($layout, columns), $i));
                    max-width: math.div(100%, math.div(map-get($layout, columns), $i));
                }
            }

            .flex-col-mobile-auto {
                flex: 0 0 auto;
            }
        }
        @media (max-width: 1023px) and (min-width: 768px) {
            @for $i from 1 through map-get($layout, columns) {
                &-tablet-#{$i} {
                    flex: 1 1 math.div(100%, math.div(map-get($layout, columns), $i));
                    max-width: math.div(100%, math.div(map-get($layout, columns), $i));
                }
            }

            .flex-col-tablet-auto {
                flex: 0 0 auto;
            }
        }

        @media (min-width: 1024px) {
            @for $i from 1 through map-get($layout, columns) {
                &-#{$i} {
                    flex: 1 1 math.div(100%, math.div(map-get($layout, columns), $i));
                    max-width: math.div(100%, math.div(map-get($layout, columns), $i));
                }
            }
        }

        &-menu {
            .flex-wrap > & {
                @media (max-width: 1040px) {
                    flex: 1 1 100%;
                }
            }

            @media (min-width: 1040px) {
                @for $i from 1 through map-get($layout, columns) {
                    &-#{$i} {
                        flex: 1 1 math.div(100%, math.div(map-get($layout, columns), $i));
                        max-width: math.div(100%, math.div(map-get($layout, columns), $i));
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        &-mobile-block {
            display: block;
        }

        &-column-mobile {
            flex-direction: column;
        }

        &-mobile-full-width {
            width: 100%;
        }
    }
}

/* Spacing */
.has-gutters {
    margin: map-get($layout, gutters);

    &-double {
        margin: map-get($layout, gutters) * 2;
    }

    &-half {
        margin: calc(map-get($layout, gutters) / 2);
    }

    &-top {
        margin-top: map-get($layout, gutters);

        &-double {
            margin-top: map-get($layout, gutters) * 2;
        }

        &-half {
            margin-top: math.div(map-get($layout, gutters), 2);
        }

        &-quarter {
            margin-top: math.div(map-get($layout, gutters), 4);
        }

        &-none {
            margin-top: 0 !important;
        }

        &-negative {
            margin-top: map-get($layout, gutters) * -1;
        }

        &-negative-double {
            margin-top: map-get($layout, gutters) * -2;
        }

        @media (max-width: 767px) {
            &-mobile {
                margin-top: map-get($layout, gutters);
            }

            &-mobile-double {
                margin-top: map-get($layout, gutters) * 2;
            }

            &-mobile-half {
                margin-top: math.div(map-get($layout, gutters), 2);
            }

            &-mobile-quarter {
                margin-top: math.div(map-get($layout, gutters), 4);
            }

            &-mobile-none {
                margin-top: 0;
            }

            &-mobile-negative {
                margin-top: map-get($layout, gutters) * -1;
            }

            &-mobile-negative-double {
                margin-top: map-get($layout, gutters) * -2;
            }
        }
    }

    &-bottom {
        margin-bottom: map-get($layout, gutters);

        &-double {
            margin-bottom: map-get($layout, gutters) * 2;
        }

        &-half {
            margin-bottom: calc(map-get($layout, gutters) / 2);
        }

        &-quarter {
            margin-bottom: math.div(map-get($layout, gutters), 4);
        }

        &-none {
            margin-bottom: 0;
        }

        @media (max-width: 767px) {
            &-mobile {
                margin-bottom: map-get($layout, gutters);
            }

            &-mobile-double {
                margin-bottom: map-get($layout, gutters) * 2;
            }

            &-mobile-half {
                margin-bottom: math.div(map-get($layout, gutters), 2);
            }

            &-mobile-quarter {
                margin-bottom: math.div(map-get($layout, gutters), 4);
            }

            &-mobile-none {
                margin-bottom: 0;
            }

            &-mobile-negative {
                margin-bottom: map-get($layout, gutters) * -1;
            }

            &-mobile-negative-double {
                margin-bottom: map-get($layout, gutters) * -2;
            }
        }
    }

    &-left {
        margin-left: map-get($layout, gutters);

        &-double {
            margin-left: map-get($layout, gutters) * 2;
        }

        &-half {
            margin-left: calc(map-get($layout, gutters) / 2);
        }

        &-quarter {
            margin-left: math.div(map-get($layout, gutters), 4);
        }

        @media (max-width: 767px) {
            &-mobile {
                margin-left: map-get($layout, gutters);
            }

            &-mobile-double {
                margin-left: map-get($layout, gutters) * 2;
            }

            &-mobile-half {
                margin-left: math.div(map-get($layout, gutters), 2);
            }

            &-mobile-quarter {
                margin-left: math.div(map-get($layout, gutters), 4);
            }

            &-mobile-none {
                margin-left: 0;
            }

            &-mobile-negative {
                margin-left: map-get($layout, gutters) * -1;
            }

            &-mobile-negative-double {
                margin-left: map-get($layout, gutters) * -2;
            }
        }
    }

    &-right {
        margin-right: map-get($layout, gutters);

        &-double {
            margin-right: map-get($layout, gutters) * 2;
        }

        &-half {
            margin-right: calc(map-get($layout, gutters) / 2);
        }

        &-quarter {
            margin-right: math.div(map-get($layout, gutters), 4);
        }
    }

    @media (min-width: 768px) {
        &-x-half-desktop {
            margin-right: calc(map-get($layout, gutters) / 2);
            margin-left: calc(map-get($layout, gutters) / 2);
        }
    }
}

.has-padding {
    padding: map-get($layout, gutters) !important;

    &-double {
        padding: map-get($layout, gutters) * 2;
    }

    &-half {
        padding: math.div(map-get($layout, gutters), 2);
    }

    &-30 {
        padding: 30px;
    }

    @media (max-width: 767px) {
        &-mobile-double {
            padding: map-get($layout, gutters) * 2;
        }

        &-mobile-none {
            padding: 0;
        }

        &-mobile-negative {
            padding: map-get($layout, gutters) * -1;
        }

        &-mobile-negative-double {
            padding: map-get($layout, gutters) * -2;
        }
    }

    &-top {
        padding-top: map-get($layout, gutters);

        &-double {
            padding-top: map-get($layout, gutters) * 2;
        }

        &-half {
            padding-top: calc(map-get($layout, gutters) / 2);
        }

        &-none {
            padding-top: 0;
        }

        &-negative {
            padding-top: map-get($layout, gutters) * -1;
        }

        &-negative-double {
            padding-top: map-get($layout, gutters) * -2;
        }

        @media (max-width: 767px) {
            &-mobile-double {
                padding-top: map-get($layout, gutters) * 2;
            }

            &-mobile-none {
                padding-top: 0;
            }

            &-mobile-negative {
                padding-top: map-get($layout, gutters) * -1;
            }

            &-mobile-negative-double {
                padding-top: map-get($layout, gutters) * -2;
            }
        }
    }

    &-bottom {
        padding-bottom: map-get($layout, gutters);

        &-double {
            padding-bottom: map-get($layout, gutters) * 2;
        }

        &-half {
            padding-bottom: calc(map-get($layout, gutters) / 2);
        }
        &-none {
            padding-bottom: 0;
        }

        @media (max-width: 767px) {
            &-mobile-double {
                padding-bottom: map-get($layout, gutters) * 2;
            }

            &-mobile-none {
                padding-bottom: 0;
            }

            &-mobile-negative {
                padding-bottom: map-get($layout, gutters) * -1;
            }

            &-mobile-negative-double {
                padding-bottom: map-get($layout, gutters) * -2;
            }
        }
    }

    &-left {
        padding-left: map-get($layout, gutters);

        &-double {
            padding-left: map-get($layout, gutters) * 2;
        }

        &-half {
            padding-left: calc(map-get($layout, gutters) / 2);
        }

        @media (max-width: 767px) {
            &-mobile-double {
                padding-left: map-get($layout, gutters) * 2;
            }

            &-mobile-half {
                padding-left: math.div(map-get($layout, gutters), 2);
            }

            &-mobile-quarter {
                padding-left: math.div(map-get($layout, gutters), 4);
            }

            &-mobile-none {
                padding-left: 0;
            }

            &-mobile-negative {
                padding-left: map-get($layout, gutters) * -1;
            }

            &-mobile-negative-double {
                padding-left: map-get($layout, gutters) * -2;
            }
        }
    }

    &-right {
        padding-right: map-get($layout, gutters);

        &-double {
            padding-right: map-get($layout, gutters) * 2;
        }

        &-half {
            padding-right: calc(map-get($layout, gutters) / 2);
        }

        @media (max-width: 767px) {
            &-mobile-double {
                padding-right: map-get($layout, gutters) * 2;
            }

            &-mobile-half {
                padding-right: math.div(map-get($layout, gutters), 2);
            }

            &-mobile-quarter {
                padding-right: math.div(map-get($layout, gutters), 4);
            }

            &-mobile-none {
                padding-right: 0;
            }

            &-mobile-negative {
                padding-right: map-get($layout, gutters) * -1;
            }

            &-mobile-negative-double {
                padding-right: map-get($layout, gutters) * -2;
            }
        }
    }

    &-none {
        padding: 0 !important;
    }
}

/* Display */
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: blue !important;
    border: 1px solid blue !important;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}
.superscript {
    vertical-align: super;
    font-size: x-large;
    top: 3px;
    position: relative;
}
.superscript-small {
    vertical-align: super;
    font-size: large;
    top: 3px;
    position: relative;
}
.superscript-xsmall {
    vertical-align: super;
    font-size: x-small;
    top: 1px;
    position: relative;
}

@media (max-width: 767px) {
    .block-mobile {
        display: block !important;
    }
}
.has-no-margin{
    margin: 0;
}
.has-margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.has-margin-set-top-bottom{
    margin: 1rem auto;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.relative {
    position: relative;
}

select, input[type="text"]{
    padding: 0.5rem;
}
select{
    border-radius: 5px;
    border: 1px solid lightgray;
    color: map-get($colors, 'dark-blue');
    padding-right: 2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: 600;
    position: relative;
    font-family: map-get($fonts, primary);
    &.white{
        background-color: white;
        color: map-get($colors, 'dark-blue');
    }
    &.blue{
        background-color: map-get($colors, 'light-blue');
        color: #fff;
        text-transform: uppercase;
        padding: 10px 3rem 10px 16px;
        font-size: 1rem;;
    }
}
.select-wrapper{
    position: relative;
    display: inline-block;
    &:after{
        content: url('../img/icon-arrow-blue.png');
        position: absolute;
        pointer-events: none;
        right: 0.75rem;
        top: 3px;
    }
    &.blue{
        &:after{
            content: url(../img/icon-arrow-white.png);
            position: absolute;
            right: 1.25rem;
            top: 5px;
            transition: all 0.3s ;
        }
    }
}
.selectdiv{
    display: inline;
    position: relative;
    &.white{
        &:after{
            content: url('../img/icon-arrow-blue.png');
            position: absolute;
            right: 0.75rem;
            top: -3px;
        }
    }
    &.blue{
        &:after{
            content: url('../img/icon-arrow-white.png');
            position: absolute;
            right: 1.25rem;
            top: -3px;
        }
    }
}
input[type="text"]{
    border: 1px solid map-get($colors, 'input-blue');
    &::placeholder{
        color: map-get($colors, 'input-blue');
        font-weight: 600;
    }
}

input[type="image"] {
    position: absolute;
    width: 33px;
    margin-top: 0px;
    margin-left: -1px;
}
.county-tippy{
    text-align: left;
    margin-top: 0 !important;
    li{
        margin-bottom: 0.25rem;
    }
    h6{
        font-size: inherit;
    }
}
.no-padding{
    padding: 0;
}
.no-margin-bottom{
    margin-bottom: 0;
}
.tippy-popper {
    max-width: 650px;
}
.state-info{
    h4{
        font-size: 16px;
    }
    h6{
        color: map-get($colors, 'dark-blue');
        font-weight: normal;
        font-size: 14px;
        span{
            color: map-get($colors, 'medium-blue');
            font-weight: 600;
        }
    }
}

.box.map {
    padding: 0;
    padding-top: 20px;
  }
  .box.stat-box {
    padding: 10px;
    margin: 0;
    border: none;
    background: #fff;
    text-align: left;
    position: relative;
    /*&:nth-of-type(odd){
        background-color: $boxGrey;
    }*/
  }
  @media all and (max-width: 767px) {
    .box.stat-box {
      padding: 15px 25px;
    }
  }
  .box.stat-box.highlight {
    text-align: center;
  }
  .box.stat-box.highlight .stat {
    color: #005952 !important;
    font-weight: 600;
    font-size: 18px;
  }
  .box.stat-box .info {
    float: right;
  }
  .box.stat-box.grey {
    background-color: #f2f4f7;
  }
  .box.stat-box.map-blue {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box.stat-box img {
    position: absolute;
    margin: -10px 0 0 0px;
  }
  .box.stat-box.wide {
    width: 110%;
    margin-left: -5%;
  }
  .box.stat-box.wide h6 {
    text-align: left;
  }
  .box.stat-box-big {
    padding: 5px 20px;
    margin: 0;
    border: none;
    background: #fff;
  }
  @media all and (max-width: 767px) {
    .box.stat-box-big {
      padding: 15px 25px;
    }
  }
  .box.stat-box-big.highlight {
    text-align: center;
  }
  .box.stat-box-big.highlight .stat {
    color: #005952;
    font-weight: 600;
    font-size: 18px;
  }
  .box.blue-box {
    background-color: #005274;
    color: #fff;
    border: none;
  }
  .box.blue-box.circle-bg {
    background: #07a;
  }
  .box.blue-box a {
    color: #fff;
  }
  .box.blue-box a.cta, .box.blue-box a.btn {
    color: #07a;
  }
  .box.blue-box h2, .box.blue-box h3, .box.blue-box h4, .box.blue-box h5, .box.blue-box h6 {
    color: #FFF;
  }
  .box.blue-box.news-box {
    width: 100%;
    background: #FFF;
  }
  .box.blue-box.news-box a {
    color: #45678d;
  }
  .box.blue-box.news-box a:hover {
    color: #788ea9;
  }
  .box.blue-box.news-box h2 {
    font-weight: 800;
  }
  .box.blue-box.news-box hr {
    border-top: 1px solid #EEE;
  }
  .box.blue-box.news-box ul {
    margin: 0;
    padding: 0;
  }
  .box.blue-box.news-box ul li {
    line-height: 1.7;
    text-align: center;
    list-style: none;
  }
  .box.squares {
    position: relative;
    margin-bottom: 35px;
  }
  .box.squares:after {
    content: "";
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -25px;
  }
  .box.centered {
    text-align: center;
  }
  .box.centered ul {
    text-align: left;
  }
  .box.arrow-large {
    border: none;
  }
  @media all and (min-width: 768px) {
    .box.arrow-large {
      position: relative;
      border-top: none;
    }
    .box.arrow-large:before, .box.arrow-large:after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      margin: auto;
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
    }
    .box.arrow-large:after {
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      border-bottom: 50px solid #fff;
      z-index: 1;
    }
    .box.arrow-large:before {
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 30px solid #f2f4f7;
      z-index: 2;
    }
  }
  .box.no-pad {
    padding-left: 0;
    padding-right: 0;
  }
  @media all and (max-width: 767px) {
    .box.no-pad {
      text-align: center;
    }
  }
  .box.low-pad {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box.thick-pad {
    padding-left: 30px;
    padding-right: 30px;
    border: none;
  }
  .box.box-white {
    background-color: #fff;
    border: none;
  }
  .box.space-top {
    margin-top: 50px;
  }
  .box.space-bottom {
    margin-bottom: 50px;
  }
  .box.no-marg-bottom {
    margin-bottom: 0 !important;
  }
  .box.full-pad {
    padding: 25px;
    border: none;
  }

.pagination{
    nav{
        .flex{
            margin: 0;
        }
        a{
            margin: 2rem 1rem;
            background-color: #0077AA;
            color: #fff;
            border: none;
            cursor: pointer;
            font-weight: 700;
            font-size: 1rem;
            text-transform: uppercase;
            font-family: Montserrat, sans-serif;
            border-radius: 5px;
            padding: 10px 36px;
            align-items: center;
            display: inline-block;
        }

    }
    .disabled {
        margin: 2rem 1rem;
        background-color: gray;
        color: #fff;
        border: none;
        cursor: default;
        font-weight: 700;
        font-size: 1rem;
        text-transform: uppercase;
        font-family: Montserrat, sans-serif;
        border-radius: 5px;
        padding: 10px 36px;
        display: inline-block;
      }
}

.main .activate {
    background-color: gray;
    color: #fff;
    border: none;
    cursor: default;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    border-radius: 5px;
    padding: 10px 36px;
    display: inline-block;
  }