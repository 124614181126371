.cls-3,
.cls-4 {
    fill: none;
    stroke-miterlimit: 10
}

.cls-3 {
    stroke: #1a9ad7;
    stroke-width: .64px;
    stroke-dasharray: 3.83
}

.cls-4 {
    stroke: #ccc;
    stroke-width: 1.28px
}

.cls-5 {
    fill: #d7d6d6;
    opacity: 1
}

.cls-6 {
    font-size: 14.54px;
    fill: #1a9ad7;
    font-family: Montserrat-Bold, Montserrat;
    font-weight: 700
}

.cls-10,
.cls-8,
.cls-9 {
    letter-spacing: -.01em
}

.cls-12 {
    fill: #bbb;
    mix-blend-mode: multiply
}

.cls-13 {
    fill: #2a140e
}

.cls-14 {
    fill: #3d271e
}

.cls-15 {
    fill: #f1ceb1
}

.cls-16 {
    fill: #f1c69f
}

.cls-17 {
    fill: #e0b794
}

.cls-18 {
    fill: #212121
}

.cls-19 {
    fill: #a6764d
}

.cls-20 {
    fill: #966c47
}

.cls-21 {
    fill: #2b2c2b
}

.cls-22 {
    fill: #e7b589
}

.cls-23 {
    fill: #d4d4d4
}

.cls-28 {
    fill: #60402d
}

.cls-29 {
    fill: #4e3424
}

.cls-30 {
    fill: #8d633a
}

.cls-32 {
    fill: #dcd5c9
}

.cls-33 {
    fill: #f2ebdc
}

.cls-34 {
    fill: #262b27
}

.cls-35 {
    fill: #404842
}

.cls-36 {
    fill: #bc8a61
}

.cls-37 {
    fill: #062533
}

.cls-38 {
    fill: #002e42
}

.cls-39 {
    fill: #1a1a1a
}

.cls-40 {
    fill: #010101
}

.cls-41 {
    fill: #373636
}

.cls-48 {
    fill: #416430
}

.cls-56 {
    fill: #3e3033
}

.cls-57 {
    fill: #231f20
}

.cls-59 {
    fill: #91c299
}

.cls-60 {
    fill: #7ea886
}

.cls-62 {
    fill: #541724
}

.cls-63 {
    fill: #88656d
}

.cls-64 {
    fill: #46141f
}

.cls-65 {
    fill: #7e5e65
}

.cls-66 {
    fill: #6c1c2d
}

.cls-74 {
    fill: #5e5042
}

.cls-75 {
    fill: #483e33
}

.cls-78 {
    fill: #74705b
}

.cls-83 {
    fill: #263138
}

.cls-88 {
    fill: #1f2020
}

.cls-90 {
    fill: #ded7cb
}

@keyframes line-effect {
    0% {
        offset-distance: 0%;
    }

    12% {
        offset-distance: 100%;
    }

    100% {
        offset-distance: 100%;
    }
}

.ball {
    fill: #1a9ad7;
    opacity: .5;
    offset-path: path('M222.35,196.94 397.37,315.47');
    offset-distance: 0%;
    animation: line-effect 9s linear infinite;
}

.ball-2 {
    offset-path: path('M694.78,19.98 701.65,145.71');
    animation-delay: 1s;
}

.ball-3 {
    offset-path: path('M291.87,41.62 397.79,34.45');
    animation-delay: 2s;
}

.ball-4 {
    offset-path: path('M560.73,201.89 680.81,297.71');
    animation-delay: 3s;
}

.ball-5 {
    offset-path: path('M9.64,121.49 217.53,185.16');
    animation-delay: 4s;
}

.ball-6 {
    offset-path: path('M556.79,91.53 560.73,194.84');
    animation-delay: 5s;
}

.ball-7 {
    offset-path: path('M398.03,174.6 560.73,194.84');
    animation-delay: 6s;
}

.ball-8 {
    offset-path: path('M397.79,34.45 560.73,194.84');
    animation-delay: 7s;
}

.ball-9 {
    offset-path: path('M217.85,181.21 397.79,34.45');
    animation-delay: 8s;
}

.ball-19 {
    offset-path: path('M454.84,342.22 643.49,351.33');
    animation-delay: 13s;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

//Universal Styles
@media (min-width: 980px) {
.map {
  position: relative;
  //width: 980px;
  //height: 612px;
  margin: auto;
  //display: flex;
  align-items: center;
  justify-content: center;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: url(https://assets.codepen.io/3735/ecosystem-bg-anim.svg) no-repeat center center;
    background-size: 75% 75%;
    transition: all .5s ease-in-out;
    pointer-events: none;
  }
  
  &.out:before {
    filter: saturate(10%) blur(2px);
    opacity: .8;
  }
  
  &-bg {
    width: 75%;
    
    path,
    polygon,
    rect {
     pointer-events: none;
    }
    
    [data-href] {
      cursor: pointer;
      
      circle {
        transition: fill .15s ease-in-out;
      }
      
      &:hover {
        circle {
          fill: #bbb;
        }
      }
    }
  }
}

.map-ecographic {
  position: relative;
  width: 980px;
  height: 612px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: url(https://assets.codepen.io/3735/ecosystem-bg-anim.svg) no-repeat center center;
    background-size: 75% 75%;
    transition: all .5s ease-in-out;
    pointer-events: none;
  }
  
  &.out:before {
    filter: saturate(10%) blur(2px);
    opacity: .8;
  }
  
  &-bg {
    width: 75%;
    
    path,
    polygon,
    rect {
     pointer-events: none;
    }
    
    [data-href] {
      cursor: pointer;
      
      circle {
        transition: fill .15s ease-in-out;
      }
      
      &:hover {
        circle {
          fill: #bbb;
        }
      }
    }
  }
}

.medallion {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 3000px;
  background-color: #f7f7f7;
  border: 10px solid #fff;
  box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, .2), inset 1px 2px 6px 1px rgba(0, 0, 0, .2);
  border-radius: 50%;
  transition: all .5s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  pointer-events: initial;
  
  &.out {
    filter: saturate(10%) blur(2px);
    transform: scale(.95);
  }
  
  &-holder {
    width: 500px;
    height: 500px;
    padding: 100px;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform-origin: 50% 50%;
    transform: scale(.55, .55);
    pointer-events: none;
  }
  
  &-center {
    width: 100%;
    height: 100%;
    transition: transform .5s ease-in-out;
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50%;
    
    &.arrows:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 102%;
      height: 100%;
      background: url(https://assets.codepen.io/3735/arrows.svg) bottom right no-repeat;
      transform: translate(25%, 15%);
      transition: all .5s ease-in-out;
    }
  }
  
  &-title {
    text-transform: uppercase;
    margin: 16px 20px 0;
    text-align: center;
    font-size: 1.8em;
    font-weight: 700;
  }
  
  &-copy {
    max-width: 70%;
    text-align: center;
    font-size: 14px;
    line-height: 1.1;
  }
  
  &-front,
  &-back {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  &-icon {
    max-width: 35%;
  }
  
  &-front {
    display: flex;
    justify-content: center;
  }
  
  &-back {
    transform: rotateY( 180deg );
    position: relative;
    
    .medallion-title {
      margin: 0 0 20px;
      max-width: 50%;
      font-size: 1.2em;
    }
    
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      opacity: .0875;
    }
  }  
   
  &:hover {
    transform: scale(1.5);
     box-shadow: 5px 5px 16px 5px rgba(0, 0, 0, .2), inset 1px 2px 6px 1px rgba(0, 0, 0, .2);
    z-index: 999;
    
    .medallion-center {
      transform: rotateY( 180deg );
      
      &.arrows:after {
        transform: translate(25%, 15%) scale(0, 0) ;
      }
    }
  }
}

// State Agencies
.state {
    bottom: -52%;
    left: -58%;
  .medallion-back {
    background: #0F5375;
      
    &:before {
      background: url(https://assets.codepen.io/3735/icon-state.svg) center center no-repeat;
    }
  }
  
  .medallion-front {
    background: #0F5375;
  }
}

// Associations
.associations {
    bottom: 48%;
    left: -32%;
  
  .medallion-back {
    background: #66a4bc;
      
    &:before {
      background: url(https://assets.codepen.io/3735/icon-associations.svg) center center no-repeat;
    }
  }
  
  .medallion-front {
    background: #8BD6F3;
  }
}

// Funding
.funding {
    bottom: 13%;
    left: -79.999%;
  
  .medallion-back {
    background: #0C5B53;
      
    &:before {
      background: url(https://assets.codepen.io/3735/icon-funding.svg) center center no-repeat;
    }
  }
  
  .medallion-front {
    background: #0C5B53;
  }
}

// Educational Institutions
.education {
    bottom: 48%;
    left: 24%;
  
  .medallion-back {
    background: #DF4751;
      
    &:before {
      background: url(https://assets.codepen.io/3735/icon-educationalinst.svg) center center no-repeat;
    }
  }
  
  .medallion-front {
    background: #DF4751;
  }
}

// Workforce Boards
.workforce {
    bottom: -52%;
    left: -3%;
  
  .medallion-back {
    background: #64448D;
      
    &:before {
      background: url(https://assets.codepen.io/3735/icon-workforce.svg) center center no-repeat;
    }
  }
  
  .medallion-front {
    background: #64448D;
  }
}

// Individuals
.individuals {
    bottom: -52%;
    left: 52%;
  
  .medallion-back {
    background: #1277AB;
      
    &:before {
      background: url(https://assets.codepen.io/3735/icon-individual.svg) center center no-repeat;
    }
  }
  
  .medallion-front {
    background: #1277AB;
  }
  
  .medallion-icon {
    max-height: 40%;
  }
}

// Businesses
.business {
    bottom: 13%;
    left: 64%;
  
  .medallion-back {
    background: #424244;
      
    &:before {
      background: url(https://assets.codepen.io/3735/icon-business.svg) center center no-repeat;
    }
  }
  
  .medallion-front {
    background: #424244;
  }
}
  
}

@media (max-width: 979px) {
  
  a {
    text-decoration: none;
  }
  
  .state {
    .medallion {
      background: #0F5375;
    }
  }
  
  .associations {
    .medallion {
      background: #8BD6F3;
    }
  }
  
  .funding {
    .medallion {
      background: #0C5B53;
    }
  }
  
  .education {
    .medallion {
      background: #DF4751;
    }
  }
  
  .workforce {
    .medallion {
      background: #64448D;
    }
  }
  
  .individuals {
    .medallion {
      background: #1277AB;
    }
  }
  
  .business {
    .medallion {
      background: #424244;
    }
  }
  
  .medallion {    
    border: 2px solid #fff;
    border-radius: 5px;
    box-shadow: none;
    background: #ccc;
    height: auto;
    padding: 30px;
    
    &:hover {
      transform: none;
      box-shadow: none;
    }
    
    &-holder {
    width: 100%;
    position: relative;
    margin: 1rem auto;
    }
    
    &-front {
      display: none;
    }
    
    &-back {
      display: block;
    }
    
    &-title {
      color: #fff;
      font-weight: bold;
      margin-bottom: 10px;
    }
    
    &-copy {
      color: #fff;
      text-decoration: none;
    }
       
  }
  
  
}

@media (max-width: 979px) {
  .map-bg {
    display: none;
  }
}