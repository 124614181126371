.quotes {
    max-width: 100%;
    @media (max-width: 768px) {
        h3{
            font-size: 0.9rem;
        }
    }
}
.swiper-wrapper {
    height: 100% !important;
  }
.home-quote{
    .swiper-autoheight, .swiper-autoheight .swiper-slide {
        height: 100%;
        // @media (max-width: 768px) {
        //     height: 550px;
        // }
        // @media (max-width: 600px) {
        //     height: 920px;
        // }
        // @media (max-width: 400px) {
        //     height: 980px;
        // }
      }
}

.swiper-quotes {
    margin-left: -40px;
    margin-right: -40px;
    .swiper-container {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
        // CSS Grid/Flexbox bug size workaround
        // @see https://github.com/kenwheeler/slick/issues/982
        min-height: 0;
        min-width: 0;
    }
}

.swiper-quotes-navigation {
    .swiper-button-prev,
    .swiper-button-next {
        all: initial;
        appearance: none;
        display: inline-flex;
        color: inherit;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &::before {
            content: '';
            border-right: 1px solid;
            border-bottom: 1px solid;
            display: inline-block;
            width: .5em;
            height: .5em;
        }
    }

    .swiper-pagination {
        padding: 0 .5rem;
    }

    .swiper-button-prev {
        transform: rotate(.375turn);
    }

    .swiper-button-next {
        transform: rotate(-.125turn);
    }
}

.flex-col-quotessliderfix {
    @media (max-width: 1023px) {
        flex-basis: 100%;
        max-width: 100%;
    }
}
