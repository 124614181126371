.coin {

    &-image {
        display: block;
        width: 100%;
        height: 100%;
    }

    &-holder {
        position: relative;
        width: 256px;
        height: 238px;
        margin: 0 auto;
    }

    &-overlay {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: auto;
        opacity: .5;
        width: 176px;
        height: 173px;
    }

    &-percent {
        background: map-get($colors, 'dark-blue');
        min-width: 40px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        padding: 6px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        position: absolute;
        top: 45px;
        right: 45px;
    }
}