@import './breakpoints';
@import './variables';
@import './functions';
@import './fonts';
@import './reset';
@import './typography';
@import './icons';
@import './layout';
@import './map';
@import './medallion';
@import './wordpress';
@import './state';
@import './region';
@import './county-table';



// baseline styles

*,
*:before,
*:after {
    box-sizing: border-box;
}

.visually-hidden:not(:focus):not(:active),
.visuallyhidden:not(:focus):not(:active) {
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0); /* for IE only */
	clip-path: inset(50%);
	position: absolute;
	white-space: nowrap;  
}

textarea,
input[type="text"],
.input-checkbox {
    -webkit-border-radius: 0px;
    -webkit-appearance: none;
}

input[type="button"],
input[type="submit"] {
    -webkit-border-radius: 5px;
    -webkit-appearance: none;
}

html,
body {
    width: 100%;
}

html {
    scroll-behavior: smooth;
}

body {}

.no-margin-top {
    margin-top: 0rem !important;
}

.margin-top {
    margin-top: 1rem;
}
.margin-top-double {
    margin-top: 2rem;
}

.margin-bottom {
    margin-bottom: 1rem;
}
.margin-bottom-double {
    margin-bottom: 2rem;
}

.margin-bottom-half {
    margin-bottom: .5rem;
}

.large-text {
    font-size: 5rem;
    line-height: .8;
}

.margin-left {
    margin-left: 1rem;
}

.margin-right {
    margin-right: 1rem;
}

.section-padding {
    padding: 3rem 0;
}

.padding {
    padding: 1rem;
}
.padding.rounded {
    padding: 30px;
  }

.skip-link {
    background: #F1F1F1;
    color: #000;
    font-weight: bold;
    left: 2%;
    padding: 10px 20px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
    z-index: 999;

    a {
        color: #000;
    }

    &:focus {
        transform: translateY(0%);
    }

    &:focus-within {
        transform: translateY(0%);
    }
}

.two-columns {
    column-count: 2;
    column-gap: 20px;

    @media (max-width: 500px) {
        column-count: 1;
        column-gap: 20px;
    }
}

.inline-flex {
    display: inline-flex;
}

.border-left {
    border-left: 1px solid #c9c8c3;

    @media (max-width: 767px) {
        border-left: 0px;
    }
}

.no-gutters-top-mobile {
    @media (max-width: 767px) {
        padding-top: 0rem;
    }
}

.no-gutters-bottom-mobile {
    @media (max-width: 767px) {
        padding-bottom: 0rem;
    }
}

.dispal-flex {
    display: flex;
}

.no-margin {
    margin: 0;
}

.right-border {
    border-right: 1px solid map-get($colors, 'border');
}

.full-height {
    height: 100%;
}

.text-contain-center {
    max-width: 40rem;
    display: block;
    margin: 0 auto
}

img.logo {
    width: 100%;
    max-width: 277px;
    margin-top: 0.75rem;
}

@each $key,
$value in $colors {
    *.is-#{$key} {
        color: $value;
    }
}

@each $key,
$value in $colors {
    *.bg-#{$key} {
        background-color: $value;
    }
}

@media (max-width: 767px) {

    @each $key,
    $value in $colors {
        *.is-#{$key}-mobile {
            color: $value;
        }
    }

    @each $key,
    $value in $colors {
        *.bg-#{$key}-mobile {
            background-color: $value;
        }
    }
}

.rounded {
    border-radius: 10px;
    overflow: hidden;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.img-center {
    display: block;
    margin: 0 auto;
}

.align-bottom {
    vertical-align: bottom;
}

.col {
    flex: 1;
}

.download-now {
    position: relative;

    &:before {
        content: url('../img/icon-pdf.png');
        left: -2.5rem;
        top: -16px;
        position: absolute;
    }
}

.blue-square {
    &:before {
        content: url('../img/blue-square.png');
        position: absolute;
        left: -25px;
    }
}

.download-now h4 {
    vertical-align: top;
}

.news-icon-contain {
    max-width: 50px;
    display: inline-flex;
    margin-right: 0.5rem;

    .news-icon {
        display: inline-block;
        top: 10px;
        position: relative;
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

.gray-info-icon {
    position: relative;
    margin-left: 1.25rem;

    &:before {
        content: url('../img/icon-Info-gray.png');
        left: -1.25rem;
        top: 2px;
        position: absolute;
    }
}

.list-icon {
    position: relative;
    margin-left: 1.75rem;

    &:before {
        content: url('../img/list.png');
        left: -1.75rem;
        top: 2px;
        position: absolute;
    }
}

.news-icon {
    display: inline-block;
    top: 0.75rem;
    position: relative;
    margin-right: 0.5rem;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;

    &:focus {
        background-color: #eee;
        clip: auto !important;
        clip-path: none;
        color: #444;
        display: block;
        font-size: 1em;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000;
        /* Above WP toolbar. */
    }
}

.hide {
    &-desktop {
        @media (min-width: 767px) {
            display: none;
        }
    }

    &-tablet {
        @media (max-width: 1023px) {
            display: none;
        }
    }

    &-mobile {
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.show {
    &-desktop {
        display: none;

        @media (min-width: 767px) {
            display: block;
        }
    }

    &-tablet {
        display: none;

        @media (max-width: 1024px) and (min-width: 768px) {
            display: block;
        }
    }

    &-mobile {
        display: none;

        @media (max-width: 767px) {
            display: block;
        }
    }
}

// storybook
.sb-show-main {
    min-height: 100vh;
}


//Homepage
header {
    position: fixed;
    z-index: 900;
    top: 0;
    height: 70px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    box-shadow: 0 0 15px rgb(0 0 0 / 13%);
}

//Navigation
nav {
    li {
        display: inline-block;
        list-style: none;
        margin-right: 0.5rem;
        margin-left: 0rem;
        padding: 22px 10px 23px 10px;

        &:last-of-type {
            margin-right: 0rem;
            padding-right: 0;
        }

        @media (max-width: 1180px) {
            margin-right: 0.5rem;
        }

        @media (max-width: 1040px) {
            margin-right: 0rem;
        }

        a {
            color: map-get($colors, 'light-blue');
            text-decoration: none;
            font-size: 14px;
            line-height: 24px;
            display: inline-block;
            width: 100%;
        }
    }
}

.intro-header {
    padding-top: 7rem;
    padding-bottom: 3.5rem;

    h5 {
        margin-bottom: 1rem;
    }
}


.sub-nav {
    position: relative;

    &.open {
        .dropdown-content {
            visibility: visible;
            /* shows sub-menu */
            opacity: 1;
            z-index: 1;
            transform: translateY(0%);
            transition-delay: 0s, 0s, 0.3s;
            /* this removes the transition delay so the menu will be visible while the other styles transition */
            display: block;
        }
    }
}

.dropdown-content {
    visibility: hidden;
    /* hides sub-menu */
    opacity: 0;
    transform: translateY(-2em);
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    top: 47px;
    .flex-wrap{
        margin: 0;
    }

    p.is-dark-blue {
        font-weight: normal;
        min-height: 7rem;
        margin-top: 1rem;
    }

    section {
        min-width: 1360px;
        padding: 0rem 10rem;
        max-height: calc(100vh - 70px);
        overflow: auto;
    }

    .full-height {
        text-align: center;
    }

    a.button {
        text-transform: capitalize;
        font-size: 0.8rem;
        display: inline-block;
        width: auto;
        padding: 8px 20px;
        border-radius: 0px;
        font-weight: 600;
        min-width: 11rem;
        margin-bottom: 0.75rem;
        font-size: 14px;
    }
}

.align-nav {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .flex-col-4>& {
        max-width: 302px;
        min-height: 297px;
        padding: 28px 16px 19px;
    }

    .flex-col-6>& {
        min-height: 243px;
        min-width: 470px;
        padding-top: 31px;
    }
}

a.is-dropdown {
    position: relative;

    &:after {
        content: url('../img/icon-arrow-blue.png');
        position: relative;
        margin-left: 0.5rem;
        top: -1px;
    }
}

span.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    margin-top: -1px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.about-sub {
    position: absolute;
    width: 100%;
    left: -35rem;
    z-index: 1;

    li {
        display: block;
    }
}

.community-sub {
    position: absolute;
    width: 100%;
    left: -40.2rem;
    z-index: 1;

    li {
        display: block;
    }
}

.employers-sub {
    position: absolute;
    width: 100%;
    left: -50.4rem;
    z-index: 1;

    li {
        display: block;
    }
}

.news-sub {
    position: absolute;
    width: 100%;
    left: -67.8rem;
    z-index: 1;

    li {
        display: block;
    }
}



@media (max-width: 1200px) {
    .tablet-mobile-center-parent {
        justify-content: center;
    }

    .tablet-mobile-center-child {
        flex: 1 1 100%;
        max-width: 44rem;
        width: 100%;

        .rounded {
            min-height: 130px;
        }
    }
}


.search {
    padding: 1rem 0;

    p {
        display: inline;
        margin: 1rem;
    }

    @media (max-width: 768px) {
        p {
            display: block;
            text-align: center;
        }

        label:before {
            display: none;
        }

        .search-contain {
            text-align: center;
        }
    }
}

.search-contain {
    display: block;
    margin: 0 auto;

    label {
        margin-right: 0.25rem;
        position: relative;
        margin-left: 3rem;

        &:before {
            content: url('../img/icon-location.png');
            left: -2.5rem;
            top: -12px;
            position: absolute;
        }
    }
}


.podcast {
    padding: 0.75rem 0rem;

    p {
        margin-top: 1rem;
        margin-left: 1rem;
        margin-bottom: 0.25rem;
    }
}
.mySlides{
    p.is-purple{
        font-size: 1rem !important;
    }
}

.hide-slide-mobile {
    @media (max-width: 600px) {
        display: none;
    }
}

.subscribe-btn {
    @media (max-width: 600px) {
        display: none;
    }

    p {
        margin-top: 1.85rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }
}

p.h3.is-purple {
    font-size: 1.125rem;
}

.appenticeship {
    padding: 0.75rem 0rem;

    p {
        margin-top: 1rem;
        margin-left: 0.5rem;
    }
}

.shaking-hands {
    background-image: url(../img/shaking-hands.png);
    background-position: center center;
    background-size: cover;

    @media (max-width: 600px) {
        display: none;
    }
}

.states {
    h2 {
        margin-bottom: 1rem;
    }

    .bg-white {
        padding: 2rem;
    }
}

.mobile-button {
    @media (min-width: 600px) {
        display: none !important;
    }

    @media (max-width: 600px) {
        display: inline-block;
    }
}

img.magnify {
    position: absolute;
    width: 33px;
    margin-top: 1px;
}
input {
    border-radius: 5px !important;
}
.search-contain{
    input[type="text"] {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    input[type="image"] {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

img.community-icon {
    display: block;
    margin: 0rem auto 0.5rem auto;

    @media (max-width: 1125px) {
        max-width: 3rem;
    }

    @media(max-width: 1028px) {
        max-width: 100%;
    }

    @media (max-width: 450px) {
        max-width: 2.5rem;
    }

    &-employer {
        max-width: 3.25rem;
    }

    &-community {
        max-width: 3.75rem;
    }

    &-educators {
        max-width: 3.75rem;
    }

}

img.icon-arrow {
    display: block;
    margin: 1rem auto;
    max-width: 1.5rem;
    @media (max-width: 450px) {
        margin-top: 0.5rem;
    }
}

.circle {
    border-radius: 50%;
    padding: 0.5rem;
    position: relative;
    z-index: 1;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 210px;
    margin: 0 auto;

    @media (max-width: 500px) {
        h3 {
            font-size: 0.9rem;
            margin-bottom: 0;
        }
    }

    @media (max-width: 400px) {
        padding: 0rem;
    }
}

.behind-circle-parent {
    position: absolute;
}

.circle-bg {
    position: relative;

    &:before,
    &:after {
        content: url('../img/behind-circle.png');
        top: 1rem;
        position: absolute;

        @media (max-width: 1024px) {
            display: none;
        }
    }

    &:before {
        left: -7rem;
    }

    &:after {
        right: -7rem;
    }
}

.community {
    padding-bottom: 5rem;

    .button {
        margin-top: 0.5rem;
    }

    h1 {
        margin-bottom: 1rem;
    }
}

.career-ready {
    p {
        margin-top: 0rem;
    }
}

.fast-facts {
    padding: 2rem;
    background-image: url(../img/blue-graphic-bg.png);
    background-position: center center;
    background-size: cover;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 1024px) {
        .right-border {
            border: none;
        }
    }

    .population {
        font-size: 0.67rem;
    }

    .flex-col-2 {
        padding: 10px;
    }
}

.workkeys {
    padding: 1rem 2rem 0rem;
    max-width: 22rem;
    margin: 1rem auto;

    h3 {
        margin-bottom: 0.5rem;
    }
}

.workkeys-chart {
    max-width: 31rem;
    margin: 2.5rem auto;

    p {
        margin-bottom: 0.5rem;
        ;
    }
}

.workkeys-stats {
    display: block;
    max-width: 20rem;
}

.workkeys,
.workkeys-stats,
.workkeys-chart {
    display: none;

    &.active-chart {
        display: block;
    }
}

@media (max-width: 1024px) {

    .workkeys,
    .workkeys-stats {
        display: block;
    }

    .workkeys-chart {
        display: none;

        &.active-chart {
            display: none;
        }
    }

    .work-key-icon.minus {
        display: inline-block;

        &.active-icon {
            display: inline-block;
        }
    }

    .work-key-icon.plus {
        display: none;

        &.active-icon {
            display: none;
        }
    }
}

.work-key-icon {
    display: none;
    max-width: 15px;
    position: absolute;
    cursor: pointer;

    &.active-icon {
        display: inline-block;
    }
}

.chart-bar {
    padding-left: 0.5rem;
    border-radius: 5px;
}

.ncrc-contain {
    max-width: 15rem;
    display: block;
    margin: 0.5rem auto;
}

.recognized-employers.odoTicker {
    margin-top: 3rem;
}

.key-growth {
    border-right: 2px solid map-get($colors, 'gray');
    padding: 0 20px;

    &:last-of-type {
        border: none;
    }

    h4 {
        color: map-get($colors, 'inactive');
    }

    &.active {
        background-color: transparent;

        .communities {
            color: map-get($colors, 'green');
        }

        .employer-support {
            color: map-get($colors, 'purple');
        }

        .job-profile {
            color: map-get($colors, 'medium-blue');
        }
    }

    &.inactive {
        h4 {
            color: map-get($colors, 'inactive');
        }
    }
}


.state-map-contain {
    position: relative;
}

.readiness-cert {
    padding: 1.5rem 3rem;

    h3 {
        font-size: 24px;

        @media(max-width: 400px) {
            font-size: 18px;
        }
    }
}

.county-map {
    max-width: 25rem;
    margin-bottom: 3rem;
    width: 100%;
}

.controls {
    position: absolute;
    bottom: 50px;
    right: 0;
}

.counties {
    padding: 2rem 0rem 2rem 2rem;
    max-width: 390px;
    margin: 0 auto 1rem;

    .county-list {
        margin-top: 0;
    }

    p {
        margin-bottom: 0.25rem;
    }
}

.spotlight {
    padding: 1rem 0;
}

.quotes {
    img {
        vertical-align: sub;
        margin-right: 0.5rem;
    }

    .padding {
        padding: 1rem 3rem;

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    h4 {
        @media (max-width: 767px) {
            font-size: 0.7rem;
        }
    }

    .right-border {
        margin-right: 1rem;
    }

    .download-now {
        position: relative;
        margin-left: 3rem;
        top: 1.25rem;
        margin-bottom: 2rem;
        display: block;
    }
}

.quote-contain {
    padding: 0rem 3rem;
    padding-bottom: 2rem;
    position: relative;
}


.state-stack-up {
    padding-left: 2rem;
    padding-top: 1.5rem;
}

.hire-bg {
    background-image: url(../img/hire-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.green-check {
    width: 100%;
    max-width: 1rem;
    float: right;
}


.slideshow-container {
    position: relative;
}

.mySlides {
    display: none;
    min-height: 113.25px;
    @media(max-width: 600px) {
        min-height: 162px !important;
    }
    @media(max-width: 400px) {
        min-height: 182px !important;
    }
    &.flex {
        margin-top: 0rem;
    }
}

.myQuotes {
    display: none;

    &.flex {
        margin-top: 0rem;
    }
}

.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

.dot-container {
    text-align: center;
    padding: 0px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
}

.dot {
    all: unset;
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: map-get($colors, 'light-blue');
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active,
.dot:hover {
    background-color: map-get($colors, 'dark-blue-bg');
}


//Find a location
.top-search {
    padding-top: 4.5rem;
    @media(max-width: 1130px) {
        .flex.flex-wrap.flex-no-gutters {
            justify-content: center;
        }
        .flex-col.flex-col-5 {
            display: none;
        }
        .responsive-search{
            display: block;
            max-width: 40rem;
            margin: auto;
        }
    }

    a {
        color: #005B83 !important;
    }

    span {
        margin-top: 1.5rem;
    }

    .search-contain {
        text-align: right;
        padding-right: 2rem;

        @media(max-width: 1130px) {
            text-align: center;
            padding-right: 0rem;
            margin: 0 auto;
        }
    }
}

.state-stats {
    text-align: center;
}

.icon-info-state {
    margin-bottom: -0.25rem;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.scroll {
    width: 100%;
    height: 145px;
    overflow: auto;
    text-align: justify;
}

.info-map-box {
    max-width: 20rem;
    position: absolute;
    bottom: 40px;
    right: 0;
    margin: 0 !important;
    padding-left: 3rem !important;

    p {
        font-size: 0.9rem;
        ;
    }

    @media (max-width: 1028px) {
        position: relative;
        bottom: 0;
    }
}

//Get Started
.get-started-form {
    text-align: left;
    overflow: visible;

    input {
        width: 100%;
        text-transform: capitalize;
    }

    .white-dropdown {
        width: 100%;
    }

    label span {
        font-size: 0.8rem;
        ;
    }
}


//County
.progress-contain {
    padding: 0rem 3rem;

    h3 {
        margin-bottom: 0.5rem;
    }

    p {
        margin-bottom: 1rem;
    }

    p,
    li {
        font-size: 0.9rem;
    }
}

.county-info-contain {
    max-width: 35rem;
    float: right;
    margin-right: 5rem;

    @media (max-width: 1200px) {
        margin: 0 auto;
        float: none;
    }
}

.economic-contain {
    padding-right: 3rem;

    ul {
        margin-top: 0.5rem;
    }
}

@media (max-width: 1028px) {

    .progress-contain,
    .economic-contain {
        padding: 0rem;
    }
}

.earned-chart {
    @media (max-width: 1278px) {
        padding: 0;
    }
}

.county-map-info {
    @media (max-width: 1028px) {
        text-align: center;
    }
}

.county-numbers {
    &.odoTicker {
        height: 40px;
    }

    &.odoTicker .odoNumber {
        width: 33px;
        background-size: contain;
    }
}

.county-employer-stats {
    h3 {
        display: inline-flex;
        max-width: 25rem;

        @media (max-width: 540px) {
            display: block;
            max-width: 100%;
        }
    }
}

.ncrc-contain.states {
    max-width: 10rem;

    a {
        font-size: 0.9rem;
    }
}

//Communities
.community-stat-parent {
    max-width: 20rem;
    display: block;
    margin: 0 auto 1rem;

    .button {
        margin-top: 1rem;
    }

    ul.dark-bulleted-list li a {
        color: white;
    }
}
.bulleted-list,.dark-bulleted-list li{
    margin-bottom: 15px;
}
.community-stat-contain {
    max-width: 12rem;
    display: block;
    margin: 0 auto;

    img {
        width: 4rem;
        margin: 0.5rem auto;
    }
}

.community-people-stats {
    max-width: 45rem;
    display: block;
    margin: 1rem auto;
    padding: 2rem;

    h2 {
        margin-bottom: 0.25rem;
        font-size: 24px;
    }
}

.how-communities {
    padding-top: 2rem;

    h2 {
        font-size: 1.5rem;
    }

    .content {
        margin-top: 0.25rem;
        margin-left: 0.5rem;
    }
}

.video-community-contain {
    background-size: cover;
    background-position: center center;
    height: 21rem;
    border-radius: 5px;
    position: relative;
    cursor: pointer;

    h3 {
        text-align: center;
        max-width: 19rem;
        margin: 0 auto;
    }

    .text-container {
        padding: 1rem 2rem;
        background-color: map-get($colors, 'dark-blue-trans');
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .play {
        position: absolute;
        top: 25%;
        left: 40%;
    }
}

//Bootcamp
.bootcamp-header {
    max-width: 25rem;
    margin: 0 auto;
    color: white;
    width: 100%;

    @media (max-width: 768px) {
        padding-bottom: .5rem;

        .img-responsive {
            margin-top: 0.25rem;
        }
    }

    @media (max-width: 500px) {
        padding-bottom: 1rem;
        max-width: 17rem;
    }

    ul li {
        color: white;
    }
}

//Resources
.resource-form {
    display: flex;

    @media (max-width: 768px) {
        display: block;
    }
}

.video-community-contain.celebrate {
    height: 15rem;
    h3 {
        font-size: 1rem;
    }

    .play {
        top: 17%;
        left: 43%;
        max-width: 4rem;
        border: none;
        background: none;

        img {
            max-width: 100%;
        }

        //this style is for the video modals that have no play button image but still need a focus indicator on top of the "built-in" play image
        &-no-img {
            width: 125px;
            height: 75px;
        }
    }
}

.play {
    top: 17%;
    left: 43%;
    max-width: 4rem;
    border: none;
    background: none;

    img {
        max-width: 100%;
    }

    &-no-img {
        height: 75px;
        width: 125px;
    }
}


.video-community-contain{
    transition: all 1s linear;
    overflow: hidden;
    .text-container{
        transition: all .5s linear;
    }
}
.video-community-contain:hover{
    transition: all 1s linear;
    .text-container {
        padding: 10rem 2rem;
        transition: all .5s linear;
        border-radius: 5px;

    }
}

//Footer
footer {
    padding: 2rem;

    li {
        margin-bottom: 0.5rem;
    }

    .button {
        margin-bottom: 4rem;
        ;
    }

    span {
        color: white;
        margin: 0 0.5rem;
    }

    a {
        color: map-get($colors, 'input-blue');
        font-size: 13px;
        margin-bottom: 2rem;
    }

    input[type=text] {
        border: 1px solid #99B8DE;
        max-width: 25rem;
        width: 100%;
    }
}

.above-footer {
    h3 {
        font-size: 1.75rem;
    }

    p {
        max-width: 55rem;
        margin: 0 auto;
    }
}

.state-login {
    position: relative;
    margin-left: 2rem;
    vertical-align: revert;
    &:before {
        content: url(../img/icon-key.png);
        position: absolute;
        left: -2rem;
    }
}

.footer-top-row {
    border-bottom: 1px solid map-get($colors, 'dark-blue');
}

.act-logo-footer {
    border-right: 1px solid map-get($colors, 'dark-blue');
    padding: 0rem 10rem 5rem 0rem;

    @media (max-width: 1160px) {
        padding: 0rem 6rem 5rem 0rem;
    }

    @media (max-width: 1028px) {
        padding: 0rem;
        border: 0rem;
    }
}

.erf-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.main .box {
    padding: 30px;
}
.site-selector-table {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    background-color: white;
    margin-top: 1rem;
}
.site-selector-table th {
    font-weight: 700;
    font-size: 16px;
    border: 1px solid #ddd;
    padding: 8px;
    background-color: #042e60;
    color: #fff!important;
}
.site-selector-table td {
    border: 1px solid #ddd;
    padding: 8px;
    color: #042e60;
}
.coin{
    position: relative;
}
.coin .coin_overlay {
    position: absolute;
    margin: auto;
    top: 20px;
    left: 0;
    z-index: 3;
    right: 0;
    opacity: 0.5;
}
.coin .coin_percentage_overlay {
    position: absolute;
    margin: auto;
    top: 12px;
    color: white;
    text-align: center;
    line-height: 65px;
    font-size: 1.2rem;
    padding: 1px;
    font-weight: bold;
    right: 31px;
    width: 65px;
    z-index: 5;
    height: 65px;
    border-radius: 50%;
    background-color: #042E60;
    opacity: 1;
}
.workkeys.workkeys-level{
    display: block;
}

.county-box {
    display: none !important;
}

.participating-county {
    fill: #2e4f7c;
    stroke: #fff;
    /* stroke-width: 0.1; */
}

.certified-county {
    fill: #c4a06f;
    stroke: #fff;
    /* stroke-width: 0.1; */
}

.active-tooltip {
    display: block !important;
    transform: translate(-50%, -101%);
    pointer-events: none;
}

.state-map svg>g>path {
    cursor: pointer;
}
.state-map-path {
    fill: #c4d0e0;
    stroke: #fff;
    stroke-width: 1px;
}
.hide{
    display: none;
}
.state-map-path + path {
    fill: #2e4f7c;
    stroke-width: 0;
}

.state-map-path.dark {
    fill: #2e4f7c;
    stroke: #fff;
    stroke-width: 1px;
}

.state-map-path.dark + path {
    fill: #fff;
}

.about-state{
    line-height: 29px;
    p{
        margin-bottom: 1rem;
    }
}

.ncrc-chart {
    display: block;
    margin: 1rem auto;
}
.responsive-chart{
    @media only screen and (max-width: 800px) {
        display: none;
    }
}
.state .graph-img {
    height: 150px;
    position: relative;
    top: 15px;
  }
  .outlined {
    padding: 30px;
    margin-bottom: 20px;
    background: #f2f4f7;
}
img.badge {
    display: block;
    margin: 0 auto;
    max-width: 8rem;
    width: 100%;
}
img.chart {
    display: block;
    margin: 0 auto;
    max-width: 10rem;
    width: 100%;
}

.column-list{
    li {
        flex-flow: row wrap;
        display: flex;
        margin-bottom: 1rem;
    }
}

span.title,span.sector,span.solution{
    font-weight: 600;
    display: block;
    margin-top: 0.75rem;
}
span.title{
    margin-top: 1rem;
    font-size: 1.25rem;
}

.video-community-contain.workkey-vid {
    height: 10rem;
}
//HOW TO EDIT TIPPY BOX POPUP
.tippy-box{
    border: 1px solid #ededed;
    border-radius: 5px;
    background-color: white;
    padding: 0.75rem;
  }
  .tippy-content{
    color: map-get($colors, 'dark-blue') !important;
    background-color: transparent;
  }

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
.table-bordered {
    border: 1px solid #ddd;
}
.table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border: 1px solid #ddd;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    text-align: left;
}
.table>thead>tr>th {
    vertical-align: bottom;
    background: #042e60;
    color: #fff;
    a{
        color: white;
    }
}

.hidden{
    display: none;
}
div#county-select {
    display: inline;
}

.contact-box{
    background-color: map-get($colors, 'light-blue') !important;
    color: white;
    max-width: 40rem;
    padding: 2rem;
}
.form-control {
    display: block;
    width: 95%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-group {
    margin-bottom: 12px;
}

.even-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fast-facts-page .blue-box {
    background-color: #f2f4f7;
    padding: 35px 50px;
}

.fast-facts-counties .first-counties .first-county:before {
    content: url(../img/trophy.png);
    position: relative;
    left: -40px;
    top: 46px;
}


.fast-facts-counties .first-counties {
    margin-left: 40px;
    margin-right: -80px;
}

.fast-facts-counties .first-counties .first-county {
    position: relative;
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
}

.fast-fact-counties .first-counties .first-county .county-name, .fast-facts .first-counties .first-county .date {
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    font-weight: 500;
}

.fast-facts-counties .first-counties .first-county .county-name, .fast-facts .first-counties .first-county .date {
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    font-weight: 500;
}

.fast-facts-counties .first-counties .first-county img {
    margin-left: -7px;
    margin-top: 20px;
    max-width: 150px;
}

.fast-facts-page .blue-box .most-recently:before {
    background-image: url(../img/most-recently.png);
    background-size: 35px 35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    content: "";
    position: absolute;
    z-index: 1;
    left: -41px;
    background-repeat: no-repeat;
    top: -7px;
}
#others-saying {
    margin: 4rem;
    @media (max-width: 768px) {
        margin: 4rem 1rem;
    }
}
.fast-facts-counties{
    display: block;
    margin: 1rem auto;
    margin-top: -50px;
}
.fast-facts-counties .first-counties h4 {
    max-width: 140px;
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    margin-bottom: 8px;
    font-size: 1.25rem;
}
.fast-facts-counties .first-counties .first-county .county-name, .fast-facts-counties .first-counties .first-county .date {
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    font-weight: 500;
}

.fast-facts-page .blue-box .largest:before {
    background-image: url(../img/largest.png);
    background-size: 20px 58px;
    display: inline-block;
    width: 20px;
    height: 58px;
    content: "";
    position: absolute;
    z-index: 1;
    left: -29px;
    background-repeat: no-repeat;
    top: 2px;
}

.fast-facts-page .blue-box .smallest:before {
    background-image: url(../img/smallest.png);
    background-size: 20px 58px;
    display: inline-block;
    width: 20px;
    height: 58px;
    content: "";
    position: absolute;
    z-index: 1;
    left: -29px;
    background-repeat: no-repeat;
    top: 2px;
}

.fast-facts-page .blue-box .most-recently:before {
    background-image: url(../img/most-recently.png);
    background-size: 35px 35px;
    display: inline-block;
    width: 35px;
    height: 35px;
    content: "";
    position: absolute;
    z-index: 1;
    left: -41px;
    background-repeat: no-repeat;
    top: -7px;
}

.fast-facts-page .blue-box .largest, .fast-facts-page .blue-box .most, .fast-facts-page .blue-box .most-recently, .fast-facts-page .blue-box .smallest {
    -webkit-margin-after: 0;
    margin-block-end: 0;
    position: relative;
    color: #333;
    font-weight: 500;
}

.fast-facts-page .blue-box .most:before {
    background-image: url(../img/most-job.png);
    background-size: 20px 58px;
    display: inline-block;
    width: 20px;
    height: 58px;
    content: "";
    position: absolute;
    z-index: 1;
    left: -29px;
    background-repeat: no-repeat;
    top: 2px;
}

@media screen and (max-width: 667px){
    .fast-facts-counties .first-counties .first-county {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 667px){
    .fast-facts-counties .first-counties .first-county:before {
        left: 0;
        top: 2px;
    }
}

@media screen and (max-width: 667px){
    .fast-facts-counties .first-counties h4 {
        max-width: 100%;
        text-align: center;
        display: block;
        margin: 0 auto;
    }
}

@media screen and (max-width: 540px){
    .most-improvers table {
        width: 100%;
        table-layout: fixed;
    }
    .most-involvement table {
        width: 100%;
        table-layout: fixed;
    }
}

.most-improvers table th small, .most-involvement table th small {
    font-size: 79%;
    font-weight: 300;
}
@media screen and (max-width: 667px){
    .fast-facts-counties .first-counties {
        display: block;
        margin: 0 auto;
        text-align: center;
    }
}

@media screen and (max-width: 540px){
    .most-improvers td a {
        font-size: 10px;
    }
}
@media screen and (max-width: 540px){
    .most-involvement td a {
        font-size: 10px;
    }
}

.video-cover iframe{
    width: 100%;
    height: auto;
}

.news-row a {
    font-size: 21px;
    line-height: 24px;
    display: block;
}

.news-row small {
    color: #757575;
    font-size: 14px;
}

.news-row p {
    margin: 0;
    padding: 5px 0;
}


.pagination {
    border: 0;
    font-weight: 600;
}

.pagination-left {
    float: left;
}
.pull-right {
    float: right;
}

.pagination-right {
    float: right;
}


.pagination:after {
    content: "";
    display: table;
    clear: both;
}

.pagination .divider {
    color: #757575;
}

img.text-center {
    display: block;
    margin: 0 auto;
}
.toolkit{
    p,h3{
        margin-bottom: 1rem;
    }
}
.session-table table td {
    border: 1px solid #042e60;
    text-align: center;
    padding: 10px;
}
.session-table table {
    width: 100%;
}
.session-table table .date {
    background: #f2f4f7;
}

.session-table table .sp-date {
    background: #042e60;
    color: #fff;
}

.mktoForm .mktoButtonWrap.mktoShadow button.mktoButton {
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    border-radius: 5px;
    padding: 10px 36px;
    align-items: center;
    display: inline-block !important;
    font-size: 14px;
    background-color: #0077AA !important;
    color: #fff;
    box-shadow: none;
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 10rem;
}

.mktoButtonRow{
    display: block !important;
    max-width: 10rem !important;
    width: 100%;
    margin: 1rem auto !important;
}

form#mktoForm_1392 {
    margin: 0 auto;
}

.mktoForm .mktoOffset {
    float: left;
    height: 1.2em;
    display: none;
}
.mktoForm .mktoGutter {
    display: none;
}
span.mktoButtonWrap.mktoShadow {
    margin-left: 0 !important;
}

li.sub-nav{
    .more{
        display: none !important;
    }
    .more.mobile-more{
        display: block !important;
    }
}

.sidebar-quotes{
    max-width: 20rem;
    display: block;
    margin: 1rem auto;
}

.pdf {
    text-align: center;
}

.fast-facts-table{
    width: 100%;
    th,td{
        text-align: left;
        border-right: 1px solid rgba(242, 244, 247, 0.2509803922);
    }
    p, a{
        margin-left: 1rem;
    }
}

button.controls-button {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    padding: 0;
    background-color: #005274;
    border: none;
    height: 100%;
    cursor: pointer;
    img {
        max-width: 8px;
        display: block;
        margin: 0 auto;
    }
}
.button-blue-bg{
    background-color: #005274;
}

.map-pan-controls {
    max-width: 6rem;
    max-height: 9rem;
    padding: 0.5rem;
    background-color: white;
    border: 1px solid #C6D0E0;
    border-radius: 15px;
}
.controls-button--down, .zoom-in{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.controls-button--up, .zoom-out{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.controls-button--right{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.controls-button--left{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}
.control-button-contain{
    height: 22px;
    line-height: 19px;
}

.map-zoom-controls {
    max-width: 1.5rem;
    margin: 0.75rem auto !important;
    border-radius: 25px;
}

div#state-map {
    position: relative;
}

.county-map-svg{
    svg {
        max-width: 271px;
    }
}

.economic-contain{
    button{
        margin-top: 1rem;
    }
}

.padding.bg-dark-blue-bg.rounded {
    margin: 3rem auto;
}

.padding.bg-pale-blue-bg.rounded {
    margin: 3rem auto;


.community-people-stats.get-started-form{
    margin: 3rem auto;
}
}
.state-select.select-wrapper.blue:after {
    right: 0.5rem;
}


@media (max-width: 976px) {
    form.national-business input, form select {
        min-width: 100%;
        display: inline;
        margin-bottom: 1rem;
    }
    .form-inline.national-business.flex {
        display: block;
      }
      form.national-business  .select-wrapper {
        width: 100%;
      }
}

@media (max-width: 600px) {
      table.national-businesses{
          th{
              font-size: 0.7rem;
          }
          td{
              font-size: 0.8rem;
          }
      }
      .state-map-contain{
          margin: 0 auto !important;
          margin-top: -2.5rem !important;
      }
      .section-padding.news-blade {
        padding-top: 0;
      }
}

span.relative.inline-flex.items-center.px-4.py-2.text-sm.font-medium.text-gray-500.bg-white.border.border-gray-300.cursor-default.leading-5.rounded-md {
    display: none !important;
  }

  ul.county-employer-list{
    column-count: 2;
    @media (max-width: 768px){
        column-count: 1;
    }
  }

summary{
    position: relative;
    .summary-title{
        cursor: pointer;
    }
    &:before{
        content: url('../img/blue-caret.png');
        position: absolute;
        left: -0.75rem;
        top: 1px;
    }
    &::marker{
        content: "";
    }
    span {
        width: 10rem;
        display: inline-block;
    }
}
details[open] > summary:before {
    content: url('../img/blue-caret.png');
        position: absolute;
        left: -0.75rem;
        top: 1px;
        transform: rotate(90deg);
}
details{
    p {
        font-size: 13px;
        line-height: 16px;
        margin-left: 0.25rem;
      }
}

  .is-underline {
    text-decoration: underline;
  }
  .is-red{
      color: #C44650;
  }

  .list-indent{
      margin-left: 0;
      li{
        margin-bottom: 0.5rem;
    }
  }

  .steps{
    .box {
        border-radius: 5px;
      }
      strong{
          color: #042E60;
      }
      textarea, input[type="text"], .input-checkbox {
        margin-top: 1rem;
        display: block;
        width: 100%;
      }
  }

  .form-container .step-map a.complete{
      background-color: #042E60;
  }
  .form-container .step-map a {
    padding: 5px 24px !important;
    text-align: center;
  }

  .box.form-group{
    color: #042E60;
  }
  #countyApplicationStep1 a{
    border-top-left-radius: 5px;
  }
  #countyApplicationStep3 a{
      border-top-right-radius: 5px;
  }
  #do-agencies {
    color: #042E60;
    .breathe-mini {
        margin-left: 0.5rem;
        margin-top: 1rem;
      }
  }

  .box.rounded.bg-pale-blue-bg.relative{
      .pull-right{
          position: absolute;
          right: 1rem;
          top: 1rem;
          cursor: pointer;
          .link-icon.close {
            position: absolute;
            top: 1rem;
            right: 0.25rem;
          }
      }
  }

  .modal-button{
      background-color: transparent;
      padding: 0;
      border: 0;
  }

  span.recommends{
    position: relative;
    &:after{
      content: url('../img/icon-recommend-gold.svg');
      position: absolute;
      right: -1rem;
      top: -0.35rem;
    }
  }

  a.recommends{
      position: relative;
      &:after{
        content: url('../img/icon-recommend-gold.svg');
        position: absolute;
        right: -1rem;
        top: -0.35rem;
      }
}

.tippy-button{
    background-color: transparent;
    padding: 0;
    border: 0;
}


.bg-footer-black{
    #Email{
        height: 36.8px !important;
    }
}

.multi-wrapper {
    display: flex;
  }


  .social-share-wrapper .share-icon {
    float:left;
    width:40px;
    height:40px;
    margin-right:10px;
    position:absolute;
    top:13px
  }
  .social-share-wrapper input {
    position:absolute;
    opacity:0;
    z-index:-1
  }
  .social-share-wrapper input[type=text] {
    opacity:1;
    position:relative;
    z-index:10
  }
  .social-share-wrapper input[type=submit] {
    opacity:1;
    position:relative;
    z-index:10
  }
  .social-share-wrapper .tabs {
    overflow:hidden;
    margin-top:40px
  }
  .social-share-wrapper .tabs h4 {
    margin:7px 0 0 50px
  }
  .social-share-wrapper .tab {
    width:100%;
    overflow:hidden;
    border-top:1px solid #eee;
    position:relative
  }
  .social-share-wrapper .tab-label {
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding:1em 0;
    cursor:pointer
  }
  .social-share-wrapper .tab-label::after {
    content:"\276F";
    width:1em;
    height:1em;
    text-align:center;
    transition:all .35s;
    color:#042e60;
    position:relative;
    top:7px
  }
  .social-share-wrapper .tab-content {
    max-height:0;
    padding:0 1em;
    transition:all .35s;
    margin:0 30px 0 50px;
  }
  .social-share-wrapper .tab-content p {
    margin:0 0 20px 0!important
  }
  .social-share-wrapper .tab-close {
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:end;
    justify-content:flex-end;
    padding:1em;
    font-size:.75em;
    cursor:pointer
  }
  .social-share-wrapper .tab form {
    margin-bottom:30px
  }
  .social-share-wrapper input:checked+.tab-label {
    background:#fff
  }
  .social-share-wrapper input:checked+.tab-label::after {
    -ms-transform:rotate(90deg);
    transform:rotate(90deg)
  }
  .social-share-wrapper input:checked~.tab-content {
    max-height:100vh;
    padding:0
  }

  .video-community-contain {
    border-radius: 5px 5px 0 0;
  }
  
  .employer-youtube-description-box {
    padding: 5px 20px 20px 20px;
    background: #f5f5f5;
    margin-top: -1px;
    border-radius: 0 0 5px 5px;
    min-height: 270px;
  }

  .county-btmrgt-container{
    background: #042e60;
    margin-bottom: 1rem;
    padding-top: 1rem;
    border: solid 1px #ccc;
  }