.bar-chart {
    height: 300px;
    display: none;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-top: 1rem;
    &.active-chart{
        display: flex;
    }

    &-scale {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }

    &-scale-label {
        position: absolute;
        transform-origin: 0% 50%;
        transform: rotate(-90deg) translateX(-50%);
        white-space: nowrap;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        color: map-get($colors, 'dark-blue');
        top: 0;
        bottom: 0;
        left: -30px;
        margin: auto;
        width: 150px;
        height: 12px;
    }

    &-level {
        position: relative;
        font-size: 12px;
        font-weight: 700;
        color: map-get($colors, 'light-blue');
        text-align: right;
        transform: translateY(50%);
        &:first-of-type{
            transform: translateY(-50%) !important;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 50%;
            left: calc(100% + 4px);
            width: 800px;
            border-bottom: 1px solid map-get($colors, 'inactive');
        }
    }

    &-column {
        display: flex;
        align-items: flex-end;
    }

    &-bar {
        width: 20px;
        height: 100%;
        background-color: rebeccapurple;
        position: relative;
    }

    &-contain {
        height: calc(100% - 18px);
        width: 100%;
        margin-top: 18px;
        display: flex;
        justify-content: space-evenly;
        position: relative;
        align-items: stretch;
      }

    &-key {
        position: absolute;
        top: calc(100% + 10px);
        left: 10px;
        right: 0;
        width: 80px;
        text-align: center;
        transform: translateX(-50%);
        font-size: 12px;
        font-weight: 700;
        color: map-get($colors, 'light-blue');
        display: block;
    }
    &.bar-chart-1{
        .bar-chart-bar{
            background-color: map-get($colors, 'green');
        }
    }
    &.bar-chart-2{
        .bar-chart-bar{
            background-color: map-get($colors, 'purple');
        }
    }
    &.bar-chart-3{
        .bar-chart-bar{
            background-color: map-get($colors, 'medium-blue');
        }
    }
}

button.workkey{
    cursor: pointer;
    background-color: transparent;
    font-weight: 600;
    border: none;
    width: 100%;
    border-right: 2px solid map-get($colors, 'gray');
    padding: 0 10px;
    color: map-get($colors, 'inactive');
    &:last-of-type{
        border: none;
    }
    &.active{
        background-color: transparent;
        &.participating-communities{
            color: map-get($colors, 'green');
        }
        &.employers-supporting{
            color: map-get($colors, 'purple');
        }
        &.job-profile{
            color: map-get($colors, 'medium-blue');
        }
    }
    &.inactive{
        color: map-get($colors, 'inactive');
    }
    &.participating-communities {
        max-width: 7rem;
    }
    &.employers-supporting {
        max-width: 6rem;
    }
    &.job-profile {
        max-width: 4rem;
    }
}
.key-growth-contain {
    max-width: 19rem;
    display: block;
    margin: 1rem auto;
}