@mixin breakpoint($point) {
	@if $point == small{
		@media (max-width: 576px) { @content; }
	}
	@if $point == lt-tablet {
		@media all and (max-width: 767px) { @content; }
	}
	@if $point == tablet {
		@media all and (min-width: 768px) { @content; }
	}
	@if $point == lt-desktop {
		@media all and (max-width: 979px) { @content; }
	}
	@else if $point == desktop {
		@media all and (min-width: 980px) { @content; }
	}
	@else if $point == widescreen {
		@media all and (min-width: 1200px)  { @content; }
	}
}