.button {
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: rem(16);
    text-transform: uppercase;
    font-family: map-get($fonts, primary);
    border-radius: 5px;
    padding: 10px 36px;
    align-items: center;
    display: inline-block;
    font-size: 14px;
    &.button-transparent {
        border: 2px solid white;
        color: white;
        text-transform: capitalize;
        padding: 0.5rem 1.5rem;
        position: relative;
        &.button-icon{
            padding: 0.5rem 1.5rem 0.5rem 2.5rem;
            img{
                position: absolute;
                left: 0.75rem;
            }
        }
    }
    &-blue {
        background-color: map-get($colors, 'light-blue');
        color: #fff;

        .icon:last-child {
            display: inline-block;
            font-size: 1.4em;
            margin-left: rem(4);
        }
    }
    &-light-blue {
        background-color: map-get($colors, 'input-blue');
        color: #000;
    }
    &-white {
        border: 1px solid map-get($colors, 'light-blue');
        transition: .3s ease-in-out;
        background-color: transparent;
        &:hover {
            transition: .3s ease-in-out;
            color: white;
            background-color: map-get($colors, 'light-blue');
        }
    }

    &-white-transparent {
        border: 1px solid map-get($colors, 'white');
        transition: .3s ease-in-out;
        background-color: transparent;
        color: map-get($colors, 'white');
        &:hover {
            border: 1px solid map-get($colors, 'white');
            transition: .3s ease-in-out;
            background-color: transparent;
            color: map-get($colors, 'white');
        }
    }

    &-scroll-down {
        font-size: rem(56);
        color: #fff;
    }
}
.mktoForm .mktoButtonWrap.mktoShadow .mktoButton:hover {
    border: none !important;
  }