.country-map {
	position: relative;

	&.blue {
		// background: url('../images/map/map-bg.png') no-repeat;
		// background-size: contain;
	}

	img {
		opacity:0;
		transition: opacity 0.3s;

		&.active {
			opacity:		1;
			transition: opacity 0.3s;
		}

		&.absolute {
			position: absolute;
		}

		&[usemap="#Map-red"] {
			z-index: 2;
		}
	}

	#map-wip {
		width: 280px;
		margin-top: 15px;
		line-height: 1.1em;
		float: right;
		font-size: 12px;
		color: #908F8B;

		a {
			color: #174B82;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	#map-info {
		position: relative;
		//top: -440px;
		left: 25px;
		font-size: 14px;
		color: #908F8B;
		text-shadow: 1px 1px #fff;
	}
}

#map-container {
	position: relative;
	height: 603px;

	@media only screen and (max-width: 576px) {
		padding: 0;
		height: 550px;
	}

	@media only screen and (max-width: 450px) {
		padding: 0;
		height: 475px;
	}

	h2 {
		// color: $body;
	}
}

.map-hover-statement {
	@include breakpoint(lt-desktop) {
		display: none;
	}
}

.state-container {
	@include breakpoint(lt-desktop) {
		display: none;
	}
}

.stats-container {
	display: none;
	position: absolute;
	margin: auto;
	width: 550px;
	//height: 120px;
	padding: 5px 20px 20px 20px;
	background: #fff;
	z-index: 2;
	//height: 200px;
	margin: -50px 0 0 -5px;
	//box-shadow: 0 0 10px #BBB;
	// border: 1px solid $lightBlue;

	h5 {
		margin-bottom: 10px;
	}

	&:after {
		content: '';
		width: 0;
		height: 0;
		display: block;
		margin: auto;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-top: 20px solid white;
		z-index: 1;
	}

	.separate {
		.col-6 {
			padding-right: 1%;
			+.col-6 {
				padding-right: 0;
				padding-left: 1%;
			}
		}
	}

	.box.stat-box {
		&:last-child {
			margin-bottom: 0;
		}

		/*&:nth-of-type(odd) {
			background-color: $white;
		}

		&:nth-of-type(even) {
			background-color: #ececec;
		}*/
	}

	&.hover {
		display: block !important;
	}
}

.legend {
	list-style: none;
	padding: 2px 0 25px;
	margin: -10px auto 0 auto;
	width: 220px;
	font-weight: 600;
	@extend %clearfix;
	@include breakpoint(small){
		pointer-events:all;
		margin-top: 20px;
	}

	@include breakpoint(lt-desktop) {
		margin: -10px auto 0 auto;
		width: 100% !important;
	}

	@include breakpoint(lt-tablet) {
		margin: -25px auto 0 auto;
		width: 85% !important;
	}

	p {
		margin: 0 0 10px 0;

		@include breakpoint(lt-desktop) {
			padding: 2px 0 10px 0;
			margin-bottom: 0;
		}
	}

	li {
		//display: inline-block;
		//background: transparent url('../img/5-squares.png') no-repeat 10px 0;
		//background-size: 15px 15px;
		padding: 15px 0 5px;
		float: left;
		margin: 0 3px;
		width: 36px;

		&.first {
			background-position: -100px 0;
		}

		&.second {
			background-position: -68px 0;
		}

		&.third {
			background-position: -42px 0;
		}

		&.fourth {
			background-position: -16px 0;
		}

		&.tag {
			background: none;
			margin-left: -30px;
		}
	}

	.blue & {
		color: #2e4f7c;
		font-size: 12px;
		padding-left: 30px;
		width: 95%;
		position: relative;

		&:before {
			content: '';
			display:block;
			height:25px;
			width:25px;
			background-color: #2e4f7c;
			position: absolute;
			left: 0;
			top: 7px;
		}

		@include breakpoint(lt-tablet) {

			&:before {
				height: 18px;
				width: 18px;
				left: 7px;
				top: 6px;
			}
		}
	}

	.about-the-data {
    	width: calc(100% + 15px);
    	text-align: left;
	}
}

.map-slide-container {
	position: relative;
	height: 653px;
}

.map-slide {
	position: absolute;
	transition: all .3s ease;
	opacity: 0;
	//z-index: 0;
	visibility: hidden;
	//left: -9999px;
	//width: 100%;

	@include breakpoint(lt-desktop){
		top:25px;
	}

	h3 {
		margin: 15px 0 0 0;
	}


	&.blue {
		//left: -100%;
	}

	&.red {
		//left: 100%;

		.legend-header {
			margin-right: -2px;
		}

		h6 {
			margin-bottom: 10px;
		}
	}

	&.active {
		left: 0;
		//z-index: 2;
		opacity: 1;
		visibility: visible;
	}
}

.slide-controls {
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 3;
	@include breakpoint(lt-tablet){
		right:20px;
		left:initial;
	}
	@include breakpoint(small){
		display:none;
		right:initial;
		left:50%;
		transform: translateX(-50%);
		bottom:10px;
	}

	a {
		padding: 10px;
		display: block;
		text-transform: none;

		&:before {
			content: '';
				width: 0;
				height: 0;
				display: inline-block;
				margin: auto;
				border-right: 7px solid #e51b24;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				margin-right: 5px;
		}

		&.heat-map {
			&:before {
				display: none;
			}
			&:after {
				content: '';
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				display: inline-block;
				margin: auto auto auto 5px;
				border-left: 7px solid #016089;
			}
		}
	}
}

.map {
	h6 {
		color: #999;
	}

	.map-main-title {
		font-size: 20px;
	}
}

.state-map-svg {
  width: 100%;
  height: auto;

  .state-map-path {
    fill: #c4d0e0;
    stroke: #fff;
    stroke-width: 1px;
    transition: fill 0.2s;
    cursor: pointer;

    [id*="text"] {
      fill: #2e4f7c;
      stroke-width: 0;
      transition: fill 0.2s;
    }

    &.dark {
      fill: #2e4f7c;

      [id*="text"] {
        fill: #fff;
      }
    }

    &:hover,
    &[aria-describedby] {
      fill: #65408e;

      [id*="text"] {
        fill: #fff;
      }
    }
  }
}

[data-tippy-root] {
  pointer-events: initial !important;
}

.tippy-box[data-theme~='white'] {
  background-color: white;
  color: #999 !important;
  border: solid 1px #eee;
  overflow: visible;
  max-width: 100% !important;
  max-width: 10rem;
  
}

.tippy-tooltip {
	max-width: none !important;
	padding: 1rem;
}

.tippy-box[data-theme~='white'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #fff;
}
.tippy-box[data-theme~='white'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #fff;
}
.tippy-box[data-theme~='white'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #fff;
}
.tippy-box[data-theme~='white'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #fff;
}

[role="tooltip"] {
	border-radius: 5px !important;
	font-size: 1rem !important;
  }